import {
  LOGOUT,
  INIT_INFORMED_CONSENT,
  INIT_SEND_PATIENT_DATA,
  SEND_PATIENT_DATA,
  ERROR_SEND_PATIENT_DATA,
  INIT_INFORMED_CONSENT_PREVIEW,
  INFORMED_CONSENT_PREVIEW,
  ERROR_INFORMED_CONSENT_PREVIEW,
  INIT_CONFIRM_INFORMED_CONSENT,
  INFORMED_CONSENT_CONFIRMED,  
  ERROR_INFORMED_CONSENT_CONFIRM,
  INIT_CONSENT_CODE_VALIDATION,
  CONSENT_CODE_VALIDATION,
  ERROR_CONSENT_CODE_VALIDATION,
  INIT_CONSENT_VALIDATE_CODE,
  CONSENT_CODE_VALIDATED,
  ERROR_CONSENT_VALIDATE_CODE,
  RESET_INFORMED_CONSENT,
  ERROR_RESET_INFORMED_CONSENT
} from 'happ-redux/happ-types';

const initialState = {
  loading: false,
  emailPatient: null,
  phonePatient: null,
  errorServer: null,
  errorMsg: null,
  confirmedConsent: false,
  codeSent:false,
  codeNumber: null,
  validatedConsent: false,
  previewPdf: null,
  currentStep: 'personal-data',
  msgValidCode: null,
  statusValidCode: null,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){
  switch(action.type){
    case INIT_INFORMED_CONSENT:
    case INIT_SEND_PATIENT_DATA:
    case INIT_INFORMED_CONSENT_PREVIEW:
    case INIT_CONFIRM_INFORMED_CONSENT:
    case INIT_CONSENT_CODE_VALIDATION:
    case INIT_CONSENT_VALIDATE_CODE:
      return {
        ...state,
        loading: true,
        errorServer: null,
      }
    case SEND_PATIENT_DATA:
      return {
        ...state,
        loading: false,
        emailPatient: action.payload.email,
        phonePatient: action.payload.phone,
        currentStep: 'confirm-preview',
        errorServer: null,
      }
    case ERROR_SEND_PATIENT_DATA:
      return {
        ...state,
        loading: false,
        currentStep: 'personal-data',
        errorServer: 'Error al enviar los datos del paciente',
      }
    case INFORMED_CONSENT_PREVIEW:
      return {
        ...state,
        loading: false,
        confirmedConsent: false,
        errorServer: null,
        errorMsg: null,    
        previewPdf: action.payload.previewPdf,
      }
    case ERROR_INFORMED_CONSENT_PREVIEW:
      return {
        ...state,
        loading: false,
        confirmedConsent: false,
        errorServer: 'Error al obtener el pdf de consentimiento',
      }
    case INIT_CONFIRM_INFORMED_CONSENT:  
      return {
        ...state,
        loading: false,
        errorServer: null,
        errorMsg: null,
      }
    case INFORMED_CONSENT_CONFIRMED:
      return {
        ...state,
        loading: false,
        confirmedConsent: true,
        errorServer: null,
        errorMsg: null,
        currentStep: 'code-validation',
      }
    case ERROR_INFORMED_CONSENT_CONFIRM:
      return{
        ...state,
        loading: false,
        errorServer: 'Error al confirmar el consentimiento',
      }
    case CONSENT_CODE_VALIDATION:
      return {
        ...state,
        loading: false,
        errorServer: null,
        errorMsg: null,
      }
    case ERROR_CONSENT_CODE_VALIDATION:
      return {
        ...state,
        loading: false,
        errorServer: 'Error al enviar el código de consentimiento',
      }
    case CONSENT_CODE_VALIDATED:
      return {
        ...state,
        loading: false,
        errorServer: null,
        errorMsg: null,
        currentStep: 'consent-validated',
        statusValidCode: null,
        msgValidCode: null,
      }
    case ERROR_CONSENT_VALIDATE_CODE:
      return {
        ...state,
        loading: false,
        msgValidCode: action.payload.error,
        statusValidCode: action.payload.statusValidCode,
        errorServer: 'Error al validar el código',
      }
    case RESET_INFORMED_CONSENT:
      return {
        ...initialState
      }
    case ERROR_RESET_INFORMED_CONSENT:
      return {
        ...state,
        errorServer: 'Error al reiniciar el consentimiento',
      }
    case LOGOUT:
      return {
        ...initialState
      }
    default:
      return state;
  }
}