import React from 'react';
import { Button, Modal } from 'react-bootstrap'

const ModalConsentError = ({
  showModal,
  onClose, 
  msgValidCode,
}) => {
  return (
    <Modal className='modal-confirm-consent' show={showModal} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Verificación pendiente</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ fontWeight: 'bold' }}>
        {msgValidCode}
      </Modal.Body>
      <Modal.Footer>
        <Button 
          className='modal-consent-button primary-bg-color' 
          onClick={onClose}
        >
          Entiendo
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalConsentError;