import {
    INIT_CHECK_TELEHEALTH_PROVIDER,
    CHECK_TELEHEALTH_PROVIDER,
    ERROR_CHECK_TELEHEALTH_PROVIDER
} from 'happ-redux/happ-types';

const initialState = {
    loading: false,
    providerType: null,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){
    switch(action.type){
        case INIT_CHECK_TELEHEALTH_PROVIDER:
            return {
                ...state,
                loading: true,
            }
        case CHECK_TELEHEALTH_PROVIDER:
            return {
                ...state,
                loading: false,
                providerType: action.payload.providerType,
            } 
        case ERROR_CHECK_TELEHEALTH_PROVIDER:
            return {
                ...state,
                loading: false,
                providerType: '',
            }   
            default:
                return state;        
    }
}