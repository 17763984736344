import { checkTelehealthProvider } from 'happ-redux/happ-services/telehealth-provider/telehealth-provider-services';
import {
    INIT_CHECK_TELEHEALTH_PROVIDER,
    CHECK_TELEHEALTH_PROVIDER,
    ERROR_CHECK_TELEHEALTH_PROVIDER
} from 'happ-redux/happ-types';

export function checkTelehealthProviderAction(medicalStageCode, branchId) {
    return async dispatch => {
        dispatch(initCheckProvider());
        try {
            const response = await checkTelehealthProvider(medicalStageCode, branchId);
            if (response.status === 200) {
                dispatch(checkProvider(response.data));
            } else {
                dispatch(errorCheckProvider());
            }
        } catch (error) {
            dispatch(errorCheckProvider());
        }
    }
}

const initCheckProvider = () => ({
    type: INIT_CHECK_TELEHEALTH_PROVIDER,
});
const checkProvider = (response) => ({
    type: CHECK_TELEHEALTH_PROVIDER,
    payload: response, 
});
const errorCheckProvider = () => ({
    type: ERROR_CHECK_TELEHEALTH_PROVIDER,
});