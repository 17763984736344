import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { 
    showAvailableSurveys,
    cancerMamaFlowV1, 
    cancerMamaFlowV2,
    cancerColonFlow,
    cancerCervixFlow,
    cancerLungFlow,
    cancerProstateFlow,
    activePreviewQuestion,
} from "./surveyValidations";
import { TypeCancerDerivationsMap } from "./questionsSurveysStructure";

const useCancerSurvey = (initState) => {
    const [state, setState] = useState(initState);
    const [surveyError, setSurveyError] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [surveyCodeTarget, setSurveyCodeTarget] = useState({});
    const [arrayStep, setArrayStep] = useState([]); 
    const [currentStep, setCurrentStep] = useState('');
    const [surveyResults, setSurveyResults] = useState([]);
    const [surveyQuestions, setSurveyQuestions] = useState({});
    const [currentQustionObj, setCurrentQustionObj] = useState({
        currentQuestion: '', 
        typeCancer: ''
    });
    const [isOm, setIsOm] = useState(false);
    const [derivations, setDerivations] = useState([]);
    const { profile } = useSelector(state => state.auth);

    useEffect(() => {
        const surveysObj = showAvailableSurveys(51, 'M');
        setSurveyQuestions(surveysObj.surveys);
        setSurveyCodeTarget(surveysObj.surveysCode);
        setArrayStep(surveysObj.arraySurveysCode);
        setCurrentStep(surveysObj.arraySurveysCode[0]);
    }, []);

    useEffect(() => {
        if (
            currentQustionObj.currentQuestion !== '' && 
            currentQustionObj.typeCancer !== ''
        ) {
            handlerNext(currentQustionObj.currentQuestion, currentQustionObj.typeCancer);
        }
    }, [state, currentQustionObj]);

    useEffect(() => {
      if (submit) {
        sendSurveyResults();
      }
    }, [submit]);
    
    const setCancerFlow = (type, currentQuestion) => {
        const currentAnswer = state[currentQuestion];
        if (type === 'cancer-cervix') {
            return cancerCervixFlow(currentQuestion, currentAnswer, surveyQuestions);
        }
        if (type === 'cancer-mama-a') {
            return cancerMamaFlowV1(currentQuestion, currentAnswer, surveyQuestions);
        }
        if (type === 'cancer-mama-b') {
            return cancerMamaFlowV2(currentQuestion, currentAnswer, surveyQuestions);
        }
        if (type === 'cancer-colon') {
            return cancerColonFlow(currentQuestion, currentAnswer, surveyQuestions);
        }
        if (type === 'cancer-pulmon') {
            return cancerLungFlow(currentQuestion, currentAnswer, surveyQuestions, state);
        }
        if (type === 'cancer-prostata') {
            return cancerProstateFlow(currentQuestion, currentAnswer, surveyQuestions, 51);
        }
    }

    const sendSurveyResults = () => {
        const derivations = [];
        const arraySurveyAnswers = surveyQuestions.map(({ question, code, typeCancer }) => ({
            question,
            answer: state[code] !== '' ? state[code] : '',
            code,
            typeCancer,
        }));
        surveyResults.map((result) => {
            if (result.om) {
                const derivation = TypeCancerDerivationsMap[result.typeCancer];
                if (derivation !== undefined && derivation !== null) {
                    derivations.push(derivation);
                }
            }
        });
        if (derivations.length) {
            setIsOm(true);
            setDerivations(derivations);
        } else {
            setIsOm(false);
            setDerivations([]);
        }
    }

    const handlerChangeAnswer = (code, answer, typeCancer) => {
        setState({
            ...state,
            [code]: answer,
        });
        setCurrentQustionObj({
            currentQuestion: code,
            typeCancer,
        });
    }

    const updateCancerSurveyResults = (typeCancerObj) => {
        const currentSurveyResults = surveyResults.map((obj) => obj);
        const isTypeCancer = surveyResults.some((obj) => obj.typeCancer === typeCancerObj.typeCancer);
        if (isTypeCancer) {
            const updateCurrentSurveyResults = currentSurveyResults.map((obj) => obj.typeCancer !== typeCancerObj.typeCancer);
            updateCurrentSurveyResults.push(typeCancerObj);
            setSurveyResults(updateCurrentSurveyResults);
        } else {
            currentSurveyResults.push(typeCancerObj);
            setSurveyResults(currentSurveyResults);
        }
    }

    const handlerNext = (currentQuestion, typeCancer) => {
        setSurveyError(false);
        const currentDataCancer = setCancerFlow(typeCancer, currentQuestion);
        setCurrentStep(currentDataCancer.updateSurveyQuestionObj.nextQuestion);
        setSurveyQuestions(currentDataCancer.updateSurveyQuestionObj.newSurveyQuestions);
        setSubmit(currentDataCancer.updateSurveyQuestionObj.finishSurveyQuestion);
        if (currentDataCancer.action === 'finish') {
            updateCancerSurveyResults(currentDataCancer.results);
        }
    }

    const handlerPreview = (code) => {
        const newArraySurveyQuestionsObj = activePreviewQuestion(surveyQuestions, code);
        setSurveyQuestions(newArraySurveyQuestionsObj.newSurveyQuestions);
        setCurrentStep(newArraySurveyQuestionsObj.nextQuestion);
    }

    return {
        state,
        surveyQuestions,
        surveyError,
        submit,
        surveyResults,
        surveyCodeTarget,
        arrayStep,
        currentStep,
        isOm,
        derivations,
        handlerChangeAnswer,
        handlerNext,
        handlerPreview
    }
}
 
export default useCancerSurvey;