import React, {useState} from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import LoginLoader from '../Loader/LoginLoader';
import ModalConsentReject from '../Modal/ModalInformedConsent/ModalConsentReject';
import ModalConsentError from 'happ-components/Modal/ModalInformedConsent/ModalConsentError';

export const ConfirmConsent = ({ onAccept, onReject, samplePDF, loading, errors, onServerError }) => {
  const [modalState, setModalState] = useState(false);

  const onConfirm = () => {
    onAccept();
  }

  const handleReject = () => {
    setModalState(true);
  }

  const modalReject = () => {
    onReject();
  }

  const modalReturn = () => {
    setModalState(false);
  }

  const modalErrorClose = () => {
    onServerError();
  }

  const onDownload = () => {
    window.open(samplePDF, '_blank');
  }

  return (
    <Container className="mt-5 mb-5">
        <Row className="justify-content-md-center ">
          {loading && <LoginLoader show={loading} />}
          <ModalConsentReject 
            isOpen={modalState}
            onReturn={modalReturn}
            onReject={modalReject}
            />
          {errors.errorServer && <ModalConsentError onClose={modalErrorClose} />}       
          <Col md={10} >  
            <Card className='m-3'>
              <Card.Title className='consent-card-title'>Consetimiento informado</Card.Title> 
                <div className="d-flex flex-column justify-content pdf-slogan">
                  <h6 className='consent-card-text'>
                    Tu consentimiento informado es importante para nosotros, nos permitirá acceder a tus resultados médicos y a comunicarnos contigo.  Acepta o rechaza las condiciones luego de leerlo.
                  </h6>
                </div>
                <div className="d-flex mt-1 mb-2">
                  <div class="btn me-2 pdf-preview-btn underline"   onClick={onDownload}>Ver consentimiento informado</div> 
                </div>
              {<Card.Body className='card-body-pdf'>
                <h6 className="consent-card-text text-end"> ¿Acepta los términos de consentimiento?</h6>
              </Card.Body>}
              <Card.Footer>
                  <div className="d-flex justify-content-end mt-3 mb-2">
                    <div class="btn consent-button-reject" onClick={handleReject}>No</div>
                    <div class="btn consent-button-confirm me-2"  onClick={onConfirm}>Si</div>
                  </div>
                <div className="d-flex justify-content-end mt-2">
                  {errors.errorServer && <p style={{ color: '#FA0F00', fontWeight: 'bold', fontSize:'12px', marginLeft:'4px' }}>{errors.errorServer}</p>}
                </div>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
    </Container>
  );
};
