import {
    INIT_SESSION,
    LOGIN,
    LOGIN_ERROR,
    INIT_VALIDATE_SESSION,
    VALIDATE_SESSION,
    VALIDATE_SESSION_ERROR,
    LOGOUT,
    INIT_ME,
    ME,
    ME_ERROR,
    INIT_UPDTAE_PROFILE,
    UPDTAE_PROFILE,
    UPDTAE_PROFILE_ERROR,
    SET_UPDTAE_PROFILE_STATUS,
    INIT_FORGET_PASSWORD,
    FORGET_PASSWORD,
    FORGET_PASSWORD_ERROR,
    INIT_RESET_PASSWORD,
    RESET_PASSWORD,
    RESET_PASSWORD_ERROR,
    VALIDATE_TERMS_AND_CONDITIONS,
    INIT_MEDICAL_STAGE_DELIVERY,
    MEDICAL_STAGE_DELIVERY,
    ERROR_MEDICAL_STAGE_DELIVERY,
    CLEAN_ALL_SCHEDULE,
    CLEAN_ALL_SCHEDULE_MEDISMART,
} from 'happ-redux/happ-types';
import {
    userAuth,
    userSuperAuth,
    validateSession,
    profileUser,
    updateProfileSession,
    sendDniPatient,
    resetPasswordPatient,
    getMedicalStageDelivery
} from 'happ-redux/happ-services/auth/auth-services';

//Init Session
export function authProcessAction(userParams, navigate) {

    const error = {
        server: 'Error en el servidor, no se pudo acceder a la plataforma'
    }

    return async (dispatch) => {
        dispatch(initSession());
        try {
            const response = await userAuth(userParams);
            if (response.data.statusCode === 200) {
                if(response.data.hasConsentSignature === false){
                    dispatch(auth(response.data));
                    navigate('/confirm-consent');
                }else{
                    dispatch(auth(response.data));
                    navigate('/');
                }
            }
            if (response.data.statusCode === 404) {
                dispatch(authError(response.data.error));
            }
            if (response.data.statusCode === 400 || response.data.statusCode === 500) {
                dispatch(authError(error));
            }
        } catch (error) {
            dispatch(authError(error));
        }
    }
}

//Init Session
export function authSuperProcessAction(userParams, navigate) {

    const error = {
        server: 'Error en el servidor, no se pudo acceder a la plataforma'
    }

    return async (dispatch) => {
        dispatch(initSession());
        try {
            const response = await userSuperAuth(userParams);
            // console.log(response)

            if (response.data.statusCode === 200) {
                dispatch(auth(response.data));
                navigate('/');
            }
            if (response.data.statusCode === 404) {
                dispatch(authError(response.data.error));
            }
            if (response.data.statusCode === 400 || response.data.statusCode === 500) {
                dispatch(authError(error));
            }
        } catch (error) {
            dispatch(authError(error));
        }
    }
}

//Validate session
export function isLogin(navigate) {

    return async (dispatch) => {
        dispatch(initValidateSession());
        try {
            const token = localStorage.getItem('token');
            if ( token !== null) {
                const response = await validateSession(token);
                // console.log(response)
                if (response.data.statusCode === 200) {
                    dispatch(session(response.data));
                } else {
                    dispatch(validataSessionError());
                    navigate('/login');
                }
            } else {
                dispatch(validataSessionError());
                navigate('/login');
            }
        } catch (error) {
            dispatch(validataSessionError());
            navigate('/login');
        }
    }
}

export function medicalStageDeliveryAction(patient, medicalStage, navigate) {
    return async dispatch => {
        dispatch(initMedicalStageDelivery());
        try {
            const token = localStorage.getItem('token');
            if (token === null) {
                dispatch(logout());
                navigate('/login');
            }
            const response = await getMedicalStageDelivery(token, patient, medicalStage);
            const { status } = response.data;
            if (status === 200) {
                dispatch(medicalStageDelivery(response.data));
            } else if (status === 404) {
                dispatch(errorMedicalStageDelivery(response.data));
            } else {
                dispatch(logout());
                navigate('/login');
            }
        } catch (error) {
            dispatch(logout());
            navigate('/login');
        }
    }
}

// Update Profile
export function updateProfileAction(patientId, profile, navigate) {
    return async (dispatch) => {
        dispatch(initUpdateProfile());
        try {
            const token = localStorage.getItem('token');
            if (token === null) {
                dispatch(logout());
                navigate('/login');
            }
            const response = await updateProfileSession(patientId, profile, token);
            
            if (response.status === 401) {
                dispatch(logout());
                navigate('/login');
            }
            if (response.status === 200) {
                dispatch(updateProfile(response.data));
                setTimeout(() => {
                    dispatch(setUpdateprofileStatus());
                }, 1000);
            } else {
                dispatch(updateProfileError(response.data.msg));
            }
        } catch (error) {
            dispatch(updateProfileError(error.data.msg));
        }
    }
}

// End Session
export function signOff(navigate) {
    return async (dispatch) => {
        dispatch(logout());
        dispatch(clean_all_schedule());
        dispatch(clean_all_schedule_medismart());
        navigate('/login');
        
        // window.location.href = '/login';
    }
}


//Email Forget Password
export function emailForgotPasswordAction(dniParams, navigate) {
    return async (dispatch) => {
        dispatch(initForgetPassword());
        try {
            const response = await sendDniPatient(dniParams);
            if (response.status === 200) {
                dispatch(forgetPassword(response));
                navigate('/email-password');
            } else {
                dispatch(forgetPasswordError(response.data.error));
            }
        } catch (error) {
            dispatch(forgetPasswordError(error.data.msg));
        }
    }
}

// Reset Password
export function resetPasswordAction(resetParams, navigate) {
    return async (dispatch) => {
        dispatch(initResetPassword());
        try {
            const response = await resetPasswordPatient(resetParams);
            if (response.data.status === 200) {
                dispatch(resetPassword(response.data));
            } else {
                dispatch(resetPasswordError(response.data.error));
            }
        } catch (error) {
            dispatch(resetPasswordError(error.data.msg));
        }
    }
}

const initSession = () => ({
    type: INIT_SESSION
});

const auth = response => ({
    type: LOGIN,
    payload: response
});

const authError = error => ({
    type: LOGIN_ERROR,
    payload: error
});

const initValidateSession = () => ({
    type: INIT_VALIDATE_SESSION
});

const session = response => ({
    type: VALIDATE_SESSION,
    payload: response
});

const validataSessionError = error => ({
    type: VALIDATE_SESSION_ERROR,
    payload: error
});

const logout = () => ({
    type: LOGOUT
});

const initMe = () => ({
    type: INIT_ME
});

const meError = error => ({
    type: ME_ERROR,
    payload: error
});

const me = profile => ({
    type: ME,
    payload: profile
});

const initUpdateProfile = () => ({
    type: INIT_UPDTAE_PROFILE
});

const updateProfile = response => ({
    type: UPDTAE_PROFILE,
    payload: response
});

const updateProfileError = error => ({
    type: UPDTAE_PROFILE_ERROR,
    payload: error
});

const setUpdateprofileStatus = () => ({
    type: SET_UPDTAE_PROFILE_STATUS
});

const initForgetPassword = () => ({
    type: INIT_FORGET_PASSWORD
});

const forgetPassword = response => ({
    type: FORGET_PASSWORD,
    payload: response
});

const forgetPasswordError = error => ({
    type: FORGET_PASSWORD_ERROR,
    payload: error
});

const initResetPassword = () => ({
    type: INIT_RESET_PASSWORD
});

const resetPassword = response => ({
    type: RESET_PASSWORD,
    payload: response
});

const resetPasswordError = error => ({
    type: RESET_PASSWORD_ERROR,
    payload: error
});

const validateTermsAndConditions = () => ({
    type: VALIDATE_TERMS_AND_CONDITIONS
});

const initMedicalStageDelivery = () => ({
    type: INIT_MEDICAL_STAGE_DELIVERY
});

const medicalStageDelivery = response => ({
    type: MEDICAL_STAGE_DELIVERY,
    payload: response
});

const errorMedicalStageDelivery = error => ({
    type: ERROR_MEDICAL_STAGE_DELIVERY,
    payload: error
});

const clean_all_schedule = () => ({
    type: CLEAN_ALL_SCHEDULE,
});

const clean_all_schedule_medismart = () => ({
    type: CLEAN_ALL_SCHEDULE_MEDISMART,
});