import {
    INIT_EXAM_HISTORY,
    EXAM_HISTORY,
    ERROR_EXAM_HISTORY,
    LOGOUT,
    INIT_GET_MEDICAL_PRESCRIPTIONS,
    GET_MEDICAL_PRESCRIPTIONS,
    ERROR_GET_MEDICAL_PRESCRIPTIONS,
} from 'happ-redux/happ-types';

const initialState = {
    loading: false,
    examHistory: null,
    errorServer: false,
    errorMsg: null,
    lastRecords: null,
    medicalPrescriptions: null,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){
    switch(action.type){
        case INIT_EXAM_HISTORY:
            return {
                ...state,
                loading: true,
            }  
        case INIT_GET_MEDICAL_PRESCRIPTIONS:
            return {
                ...state,
                loading: true,
                medicalPrescriptions: null,
            }  
        case GET_MEDICAL_PRESCRIPTIONS:
            return {
                ...state,
                loading: false,
                medicalPrescriptions: action.payload,
            }    
        case ERROR_GET_MEDICAL_PRESCRIPTIONS:
            return {
                ...state,
                loading: false,
                medicalPrescriptions: null,
            }
        case EXAM_HISTORY:
            return {
                loading: false,
                examHistory: action.payload,
                errorServer: false,
                errorMsg: null,
                // lastRecords: action.payload.lastRecords
            }
        case ERROR_EXAM_HISTORY:
            return {
                loading: false,
                examHistory: null,
                errorServer: true,
                errorMsg: action.payload,
                lastRecords: null
            }        
        case LOGOUT:
            localStorage.clear();
            return {
                loading: false,
                examHistory: null,
                errorServer: false,
                errorMsg: null,
                lastRecords: null
            }  
        default:
            return state
    }
}