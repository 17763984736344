import axiosClient from 'happ-config/axios';

const checkTelehealthProvider = async (medicalStageCode, branchId) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
        };
        const response = await axiosClient.get(`/telehealth-providers/${medicalStageCode}/${branchId}`, { headers });
        return response;
    } catch (error) {
        const { response } = error;
        return response;
    }
}

export {
    checkTelehealthProvider,
}