import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { HappContentIndicator } from 'happ-components/HappStyledComponents/HappContentIndicator/HappContentIndicator'
import { Row, Col } from 'react-bootstrap';
import { Recipe, Consultation, OtherIndications, MyTreatmentMessage } from 'happ-icons/icons';


const MyTreatment = ({
    lastPrescription,
    lastConsultation,
    otherExams
}) => {
    const [links, setLinks] = useState({
        medicaments: '',
        exams: '',
    });
    const { medicalPrescriptions } = useSelector(state => state.exams);
    useEffect(() => {
        if (medicalPrescriptions) {
            setLinks({
                medicaments: medicalPrescriptions.medicaments ? medicalPrescriptions.medicaments.link : '', 
                exams: medicalPrescriptions.exams ? medicalPrescriptions.exams.link : '', 
            })
        }
    }, [medicalPrescriptions]);
    
    const handleClick = (file) => {
        if (file) {
            window.open(file, '_blank'); 
        }
    };
    return ( 
        <>
            <Row className="pt-3">
                <div className="d-flex justify-content-between">
                    <HappContentIndicator
                        className={'box-content-flex box-indicator bg-white mb-4'}
                        onClick={() => handleClick(links.medicaments !== '' ? links.medicaments : null)}
                        role="button"
                    >
                        <Col>
                            <Row className='mt-3' >
                                <Recipe
                                    fill={links.medicaments === '' ? '#7C7C7C' : '#04477A'}
                                    responsive='scale-mobile'
                                />
                            </Row>
                            <div style={{ fontSize: '10px', lineHeight: '17px' }} className='primary-color-text'>Medicamentos</div>
                            <Row className={links.medicaments === '' ? 'dark-opacity border-bottom-container' : 'border-bottom-container-exam blue-border'}>
                                <Col >
                                    <p style={{ fontWeight: '400' }}> ver más</p>
                                </Col>
                            </Row>
                        </Col>
                    </HappContentIndicator>
                    {/* <HappContentIndicator
                        className={'box-content-flex box-indicator bg-white mb-4'}
                        onClick={() => handleClick(lastConsultation)}
                        role="button"
                    >
                        <Col>
                            <Row className='mt-3' >

                                <Consultation
                                    fill={lastConsultation === null ? '#7C7C7C' : '#04477A'}
                                    responsive='scale-mobile'
                                />

                            </Row>
                            <div style={{ fontSize: '10px', lineHeight: '17px' }} className='primary-color-text'>Interconsultas</div>

                            <Row className={lastConsultation === null ? 'dark-opacity border-bottom-container' : 'border-bottom-container-exam blue-border'}>
                                <Col >
                                    <p style={{ fontWeight: '400' }}> ver más</p>
                                </Col>
                            </Row>
                        </Col>
                    </HappContentIndicator> */}
                    <HappContentIndicator
                        className={'box-content-flex box-indicator bg-white mb-4'}
                        onClick={() => handleClick(links.exams !== '' ? links.exams : null)}
                        role="button"
                    >
                        <Col>
                            <Row className='mt-3' >
                                <OtherIndications
                                    fill={links.exams === '' ? '#7C7C7C' : '#04477A'}
                                    // #7C7C7C
                                    responsive='scale-mobile'
                                />
                            </Row>
                            <div style={{ fontSize: '10px', lineHeight: '17px' }} className='primary-color-text'>Exámenes</div>
                            <Row className={links.exams === '' ? 'dark-opacity border-bottom-container' : 'border-bottom-container-exam blue-border'}>
                                <Col >
                                    <p style={{ fontWeight: '400' }}> ver más</p>
                                </Col>
                            </Row>
                        </Col>
                    </HappContentIndicator>
                </div>
            </Row>
            <div className='d-flex justify-content-center px-1 treatment-icon-content'>
                <MyTreatmentMessage />
            </div>
        </>
    );
}
 
export default MyTreatment;