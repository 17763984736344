export const SURVEYQUESTIONS = {
    'cancer-mama-a': [
        '¿Usted se ha realizado una mamografía dentro del últimos 2 años?'
    ],
    'cancer-mama-b': [
        '¿Su madre, hermana o hija ha tenido o tiene de Cáncer de Mama?', 
        '¿Usted se ha realizado una mamografía dentro del últimos 2 años?'

    ],
    'cancer-cervix': [
        '¿Usted se ha realizado un PAP dentro de los últimos 3 años?'
    ],
    'cancer-colon': [
        '¿Sus padres, hermanos o hijos han tenido o tienen de Cáncer de Colon antes de sus 60 años?', 
        '¿Se ha realizado una colonoscopia los últimos 5 años?'
    ],
    'cancer-pulmon': [
        '¿Sus padres, hermanos o hijos han tenido o tienen de Cáncer de Pulmón?',
        '¿Usted ha fumado cerca de 1 cajetilla diaria por 20 años o más? o ¿Ha fumado por 10 año o más 2 o más cajetillas diarias? ',
        '¿tiene o ha tenido fibrosis pulmonar o enfisema pulmonar?',
        '¿En su trabajo tiene exposición ambiental a asbesto o arsénico?',
        '¿Se ha realizado un TAC de tórax el último año?',
    ],
    'cancer-prostata': [
        '¿Su padre, hermano o hijos han tenido o tienen de Cáncer de Próstata?',
        '¿Usted se ha realizado algún examen de la próstata el último año?',
    ],
}

export const healthForecastLinks = {
    'Fonasa': 'https://www.supersalud.gob.cl/difusion/665/w3-article-6317.html',
    'Isapre Colmena': 'https://www.colmena.cl/convenio-bono-costo-cero/',
    'Isapre Cruz Blanca': 'https://cruzblanca.cl/convenios',
    'Isapre Banmédica': 'https://www.banmedica.cl/red-ppi/',
    'Isapre Consalud': 'https://www.consalud.cl/beneficios/bonos-costo-cero.html',
    'Isapre Vida tres': 'https://www.vidatres.cl/red-de-prestadores-ppi/',
    'Isapre Esencial': 'https://www.somosesencial.cl/prevencion/chequeate-alemana-esencial',
    'Isapre Nueva masvida': 'https://www.nuevamasvida.cl/bonos-costo-0/',
}


export const TypeCancerDerivationsMap = {
    'cancer-colon': 'Colonoscopía',
    'cancer-prostata': 'Antígeno Prostático específico',
    'cancer-pulmon': 'TAC Pulmón bajo contraste'
}