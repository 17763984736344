import { Col } from 'react-bootstrap';

const SuccessResults = () => {
    return (
        <>
            <Col md={9} lg={9} sm={12} className='mt-3 text-center'>
                <h4 className='title-results'>Según tu perfil de riesgo</h4>
            </Col>
            <Col md={9} lg={9} sm={12} className='mt-3 text-center'>
                <h2 className='slogan-results'>No te encuentras en situación de riesgo actual</h2>
            </Col>
            <Col md={9} lg={9} sm={12} className='mt-3 text-center'>
                <p>Recuerda que esta condición cambia con la edad por lo que te repetiremos algunas de las preguntas cada año.</p>
            </Col>
            <Col md={9} lg={9} sm={12} className='mt-5 text-center'>
                <p>Si quieres saber más sobre la prevención del cáncer, te invitamos a leer <strong><a className='article-link' href='#'>este articulo</a></strong> o a contactarnos a este número</p>
            </Col>
        </>
    );
}
 
export default SuccessResults;