import { Col, Row, Button } from "react-bootstrap";
import Arrow from './arrow.svg';

const TimeLineSurvey = ({ 
    questionObj, 
    handlerChangeAnswer,
    handlerPreview,
}) => {
    const { 
        code, 
        question, 
        typeCancer, 
        currentQuestion, 
    } = questionObj;
    return (
            <>
            <Row 
                className={`justify-content-md-center ${currentQuestion ? 'showQuestion' : 'hideQuestion'}`}
            >
                <Col md={9} sm={12} lg={9} className="col-question">
                    {question}
                </Col>
                <Col className='d-flex col-btns justify-content-center' md={9} sm={12} lg={9}>
                    <Button
                        className="circle-btn btn-outline-danger"
                        onClick={() => handlerChangeAnswer(code, 'No', typeCancer)}
                    >No</Button>
                    <Button
                        className="circle-btn btn-success"
                        onClick={() => handlerChangeAnswer(code, 'Si', typeCancer)}
                    >Si</Button>
                </Col>
                <Col className='col-btn-back d-flex justify-content-center' md={9} sm={12} lg={9}>
                    <Button 
                        onClick={() => handlerPreview(code)}
                        className='shadow btn-grey-survey'
                    >
                        <div className='d-flex justify-content-center content-btn'>
                            <img src={Arrow} alt="Atras" />
                            <p>
                                Volver
                            </p>
                        </div>
                    </Button>
                </Col>
            </Row>  
            </>  
    );
}
 
export default TimeLineSurvey;