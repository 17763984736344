import React from 'react';
import { HappContainer } from 'happ-components/HappStyledComponents/HappContainer/HappContainer';
import { Row } from 'react-bootstrap';
import useInformedConsent from 'happ-custom-hooks/useInformedConsent/useInformedConsent';
import logo from 'assets/imgs/imag_.png';

const initState = {
    currentStep: "personal-data",
}
const ConsentConfirmView = () => {

    const {
        state,
        stepStructure
    } = useInformedConsent(initState);

    const { currentStep } = state;

    return ( 
        <Row className="mt-content">
            <HappContainer>
                <>
                    <div className="mt-5">
                        <div className="text-center">
                            <img src={logo} alt="description"/>
                        </div>
                    </div>
                    {stepStructure(currentStep)}
                </>
            </HappContainer>
        </Row>    
    );
}

export default ConsentConfirmView;