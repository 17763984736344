import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

export const ValidatedConsent = ({ onFinish }) => {

  return (
    <Container className="mb-5">
      <Row className="justify-content-md-center ">
        <Col md={6}>
          <Card className='m-3'>
            <Card.Title className='consent-card-title'>¡Todo Listo!</Card.Title>
            <Card.Body>
              <div className="d-flex flex-column justify-content-start">
                <h7 className='consent-card-text mb-4'>Hemos validado tu número de teléfono y firmado tu consentimiento.</h7>
              </div>
              <div className="d-flex flex-column justify-content-start">
                <h7 className='consent-card-text'>Ahora estás listo para usar happ. ¡Disfruta de tu experiencia!</h7>
              </div>
              <div className="d-flex justify-content-center mt-5">
                  <div class="btn consent-button"  onClick={onFinish}>Comenzar mi viaje</div>
              </div>
            </Card.Body>
          </Card>
          
        </Col>
      </Row>
    </Container>
  );
};