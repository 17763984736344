import {
    INIT_GET_SCHEDULE_AVAILABILITY,
    GET_SCHEDULE_AVAILABILITY,
    ERROR_GET_SCHEDULE_AVAILABILITY,
    INIT_GET_FILTER_SCHEDULES,
    GET_FILTER_SCHEDULES,
    ERROR_GET_FILTER_SCHEDULES,
    INIT_CONFIRM_SCHEDULE,
    CONFIRM_SCHEDULE,
    ERROR_CONFIRM_SCHEDULE,
    INIT_CHECK_SCHEDULES,
    CHECK_SCHEDULES,
    ERROR_CHECK_SCHEDULES,
    INIT_CURRENT_SCHEDULES,
    CURRENT_SCHEDULES,
    ERROR_CURRENT_SCHEDULES,
    INIT_DELETE_SCHEDULE_M,
    DELETE_SCHEDULE_M,
    ERROR_DELETE_SCHEDULE_M,
    CLEAN_ALL_SCHEDULE_MEDISMART
} from 'happ-redux/happ-types';

const initialState = {
    loadingCheckMedismart: false,
    loading: false,
    specialist: null,
    days: null,
    hours: null,
    availability: false,
    currentSchedule: false,
    doctor: null,
    day: null,
    attention: null,
    attentionDate: null,
    filter: false,
    loadingFilter: false,
    hour: null,
    confirmDelete: false,
}    
// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){
    switch(action.type){
        case INIT_GET_SCHEDULE_AVAILABILITY:
        case INIT_CONFIRM_SCHEDULE:    
        case INIT_CURRENT_SCHEDULES:    
        case INIT_DELETE_SCHEDULE_M:
            return {
                ...state,
                loading: true,
                confirmDelete: false,
            }
        case INIT_CHECK_SCHEDULES:
            return {
                ...state,
                loadingCheckMedismart: true,
                availability: false,
            }
        case INIT_GET_FILTER_SCHEDULES:    
            return {
                ...state,
                loadingFilter: true,
            }
        case GET_SCHEDULE_AVAILABILITY:
            return {
                ...state,
                loading: false,
                specialist: action.payload.specialist,
                availability: true,
            }
        case ERROR_GET_SCHEDULE_AVAILABILITY:
            return {
                ...state,
                loading: false,
                specialist: null,
                availability: false,
            }
        case GET_FILTER_SCHEDULES:
            return {
                ...state,
                days: action.payload.days,
                hours: action.payload.hours,
                loadingFilter: false,
                filter: true,
            }    
        case ERROR_GET_FILTER_SCHEDULES:
            return {
                ...state,
                days: null,
                loadingFilter: false,
                hours: null,
                loading: false,
            }      
        case CHECK_SCHEDULES:  
            return {
                ...state,
                loadingCheckMedismart: false,
                availability: true,
            } 
        case ERROR_CHECK_SCHEDULES:  
            return {
                ...state,
                loadingCheckMedismart: false,
                availability: false,
            }            
        case CONFIRM_SCHEDULE: 
            return {
                ...state,
                loading: false,
                currentSchedule: true,
                attention: action.payload.attention,
                attentionDate: action.payload.attentionDate,
            } 
        case ERROR_CONFIRM_SCHEDULE: 
            return {
                ...state,
                loading: false,
                currentSchedule: false,
                attention: null,
                attentionDate: null,
            }   
        case CURRENT_SCHEDULES:
            return {
                ...state,
                loading: false,
                currentSchedule: true,
                doctor: action.payload.schedule.doctor,
                attentionDate: action.payload.schedule.attentionDate,
                attention: action.payload.schedule.attention,
            }  
        case ERROR_CURRENT_SCHEDULES:
            return {
                ...state,
                loading: false,
                currentSchedule: false,
                doctor: null,
                attentionDate: null,
                attention: null,
            }  
        case DELETE_SCHEDULE_M:
            return {
                ...state,
                loading: false,
                confirmDelete: true,
                currentSchedule: false,
            }          
        case ERROR_DELETE_SCHEDULE_M:
            return {
                ...state,
                loading: false,
                confirmDelete: false,
                currentSchedule: true,
            } 
        case CLEAN_ALL_SCHEDULE_MEDISMART:
            return {
                loading: false,
                specialist: null,
                days: null,
                hours: null,
                availability: false,
                currentSchedule: false,
                doctor: null,
                day: null,
                attention: null,
                attentionDate: null,
                filter: false,
                loadingFilter: false,
                hour: null,
                confirmDelete: false,
            }      
        default:
            return state;
    }
}    