import { Col } from "react-bootstrap";

import SuccessResults from "./Components/SuccessResults";
import AlertResults from "./Components/AlertResults";

const SurveyDelivery = ({isOm, derivations}) => {
    return (
        <>
            {isOm ? (
                <AlertResults
                    derivations={derivations}
                />
            ) : (
                <SuccessResults />
            )}
        </>
    );
}
 
export default SurveyDelivery;