import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useModal } from 'happ-custom-hooks/useModal/useModal';
import { SuccessCalendarhr } from 'happ-icons/icons';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/min/locales';

const ConfirmScheduleMedismart = () => {
    moment.locale('es');
    const {attentionDate, confirmDelete} = useSelector(state => state.medismart);
    const {
        DeleteMedismart,
        openModal
    } = useModal();
    return ( 
        <Container className='text-center mt-5 mb-5' >
            {!confirmDelete ? (
                <Row className=''>
                    <Col>
                        <h3 className='text-center mb-4 primary-color-text'>
                                <b>¡Tu hora ya <br /> está agendada con éxito!</b>
                        </h3>
                        <div className='d-flex justify-content-center' >
                            <div className='text-center btn-success-calendarhr'>
                                <SuccessCalendarhr />
                            </div>
                        </div>
                        <div className='text-center mt-4 primary-color-text'>
                            <p className='p-success-schedule' ><strong>¡Tu hora está agendada con exito!</strong></p>
                            {/* <p className='p-success-schedule' ><strong>{attentionDate}</strong></p> */}
                        </div>
                    </Col>
                </Row>
            ) : (
                <Row className=''>
                    <Col>
                        <h3 className='text-center mb-4 primary-color-text'>
                            <b>¡Tu hora ya <br /> fue eliminada con éxito!</b>
                        </h3>
                    </Col>
                </Row>    
            )}
            <Row className='py-3' style={{ width: '32%', margin: '0 auto' }}>
                <div className='mb-3 d-flex justify-content-center'>
                    <Link to="/">
                        <Button className='shadow-lg btn-blue-back-travel'>
                            Regresar a mi viaje
                        </Button>
                    </Link>
                </div>
                {!confirmDelete ? (
                    <Col className='d-flex justify-content-center'>
                        <div className='ms-1'>
                            <Button 
                                onClick={openModal} 
                                className='shadow-lg danger-bg-color btn-time-success-calendar'
                            >
                                Eliminar hora
                            </Button>
                        </div>
                    </Col>
                ) : null}
            </Row>
            <DeleteMedismart />
        </Container>
    );
}
 
export default ConfirmScheduleMedismart;