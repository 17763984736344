import React from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { HappContentIndicator } from 'happ-components/HappStyledComponents/HappContentIndicator/HappContentIndicator';
import { OverweightIcon, PressureIndicator, ColesterolIndicator, DiabetesIndicator } from 'happ-icons/icons';
import { useModal } from 'happ-custom-hooks/useModal/useModal';
import CancerIcon from 'assets/imgs/cancer-icon.svg';

const MedicalStageStaticDelivery = () => {
    const navigate = useNavigate();
    const {
        openModalStaticDeliver,
        ShowModalStaticDelivey,
    } = useModal();
    const redirectLink = () => {
        navigate('/prevencion-cancer');
    }
    return (
            <>
                <div className="indicators-content">
                    <Row className='px-2'>
                        <Col md={6} xs={6} lg={6} className="px-0 pt-4  col-indicator-mobile">
                            <HappContentIndicator
                                className={'box-content primary-bg-color mb-4'}
                                onClick={openModalStaticDeliver('colesterol')}
                                role="button"
                            >
                                <Row>
                                    <div className='my-2'>
                                        <ColesterolIndicator
                                            fill='white'
                                            responsive='scale-mobile'
                                        />
                                    </div>
                                </Row>
                                <Row className={'border-bottom-container blue-border'}>
                                    <Col >
                                        <p> Colesterol</p>
                                    </Col>
                                </Row>
                            </HappContentIndicator>

                            <HappContentIndicator
                                className={'box-content primary-bg-color mb-4'}
                                onClick={openModalStaticDeliver('presion')}
                                role="button"
                            >
                                <Row>
                                    <div className='my-2'>
                                        <PressureIndicator
                                            fill='white'
                                            responsive='scale-mobile'
                                        />
                                    </div>
                                </Row>
                                <Row className={'border-bottom-container blue-border'}>
                                    <Col >
                                        <p> Presión Arterial</p>
                                    </Col>
                                </Row>
                            </HappContentIndicator>
                        </Col>

                        <Col md={6} xs={6} lg={6} className="px-0 pt-4  col-indicator-mobile">
                            <HappContentIndicator
                                className={'box-content primary-bg-color mb-4'}
                                onClick={openModalStaticDeliver('imc')}
                                role="button"
                            >
                                <Row>
                                    <div className='my-2'>
                                        <OverweightIcon
                                            fill='white'
                                            responsive='scale-mobile'
                                        />
                                    </div>
                                </Row>
                                <Row className={'border-bottom-container blue-border'}>
                                    <Col >
                                        <p> Control de Peso</p>
                                    </Col>
                                </Row>
                            </HappContentIndicator>

                            <HappContentIndicator
                                className={'box-content primary-bg-color mb-4'}
                                onClick={openModalStaticDeliver('diabetes')}
                                role="button"
                            >
                                <Row>
                                    <div className='my-2'>
                                        <DiabetesIndicator
                                            fill='white'
                                            responsive='scale-mobile'
                                        />
                                    </div>
                                </Row>
                                <Row className={'border-bottom-container blue-border'}>
                                    <Col >
                                        <p>Azúcar</p>
                                    </Col>
                                </Row>
                            </HappContentIndicator>
                        </Col>
                        {/* <Col md={12} xs={12} lg={12} className="px-0  col-indicator-mobile">
                            <HappContentIndicator
                                className={'box-content primary-bg-color mb-4'}
                                onClick={redirectLink}
                                role="button"
                            >
                                <Row>
                                    <div className='my-2'>
                                        <img className='cancer-icon-img' src={CancerIcon} alt='prevencón de cancer' />
                                    </div>
                                </Row>
                                <Row className={'border-bottom-container blue-border'}>
                                    <Col >
                                        <p> Prevención del Cáncer</p>
                                    </Col>
                                </Row>
                            </HappContentIndicator>
                        </Col> */}
                        <ShowModalStaticDelivey />
                    </Row>
                </div>
            </>
    )
}
 
export default MedicalStageStaticDelivery;