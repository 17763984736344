import { Col, Button } from 'react-bootstrap';

const AlertResults = ({ derivations }) => {
    const handleClick = () => {
            window.open('https://patientexamrepository.s3.sa-east-1.amazonaws.com/cancer/17266197-1_2024-11-25_13:46:00/medical-orders_c_17266197-1.pdf', '_blank'); 
    };
    return (
        <>
            <Col md={9} lg={9} sm={12} className='mt-3 text-center'>
                <h4 className='title-results'>Según tu perfil de riesgo</h4>
                <h6 className='fw-bold'>Te recomendamos realizarte una prueba de:</h6>
            </Col>
            {derivations && derivations.length ? (
                <Col md={9} lg={9} sm={12} className='mt-3'>
                    <ul className='derivation-list'>
                        {derivations.map((derivation, position) => (
                            <li
                                key={position}
                                className='derivation'
                            >
                                {derivation}
                            </li>    
                        ))}
                    </ul>
                </Col>
            ) : null}
            <Col md={9} lg={9} sm={10} className='mt-3 text-center'>
                <p className='fw-bold'>Es importante que te hagas el este examen una vez al año.</p>
            </Col>
            <Col md={9} lg={9} sm={12} className='mt-5 text-center'>
                <p className='fw-bold'>Te enviamos tu orden médica por Email y WhatsApp</p>
            </Col>
            <Col md={9} lg={9} sm={12} className='mt-1 text-center'>
                <Button
                    onClick={handleClick}
                    className='primary-bg-color'
                >Descargar Orden Médica</Button>
            </Col>
            <Col md={9} lg={9} sm={12} className='mt-5 text-center'>
                <p className=''>Para más información contáctanos</p>
            </Col>
        </>
    );
}
 
export default AlertResults;