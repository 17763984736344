import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { useModal } from 'happ-custom-hooks/useModal/useModal'
import { Button, Col, Row, Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import moment from 'moment';
import 'moment/min/locales';

import {
    checkMedismartSchedulesAction,
    getCurrentMedismartScheduleAction
} from 'happ-redux/happ-actions/medismartSchedule/medismartScheduleAction';

const HomeMessageMedismart = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    moment.locale('es');
    const { profile } = useSelector(state => state.auth);
    useEffect(() => {
        if (profile) {
            dispatch(checkMedismartSchedulesAction(profile.dni, navigate));
            dispatch(getCurrentMedismartScheduleAction(profile._id, navigate));
        }
    }, [profile])
    const {
        ShowModalSchedulePatient,
        openModal,
    } = useModal();
    const { currentSchedule, availability, attentionDate, loadingCheckMedismart } = useSelector(state => state.medismart);

    const formatDate = (attentionDate) => {
        const dateObj = moment(attentionDate, "DD-MM-YYYY HH:mm:ss").toDate();

        const weekday = dateObj.toLocaleString("es-ES", { weekday: "long" });
        const day = dateObj.toLocaleString("es-ES", { day: "numeric" });
        const month = dateObj.toLocaleString("es-ES", { month: "long" });
        const time = dateObj.toLocaleString("es-ES", {
            hour: "numeric",
            minute: "numeric",
        });

        return `${weekday} ${day} de ${month} a las ${time} hrs.`;
    }


    return (
        <>
            <div className='blue-stripe'>
                {loadingCheckMedismart ? (
                    <div className='text-center mt-2 mb-5'>
                        <Spinner 
                            animation="border"
                            className='text-center spinner-loading-days'
                        />
                    </div>
                ) : (
                    <>
                        {!currentSchedule && availability ? (
                            <>
                                <Row>
                                    <Col className=' text-center'>
                                        <h1 className='fw-bold primary-color-text title-message'>Telemedicina</h1>
                                        <p className='primary-color-text lh-1 subtitle-message'>
                                            {/* Programa tu próxima etapa: <strong> Telemedicina</strong> */}
                                            Nuestro médico te espera para <strong>comenzar tu tratamiento</strong>.
                                        </p>
                                    </Col>
                                </Row>
                                <Row className='mb-3'>
                                    <Col className=' text-center'>
                                        <Button onClick={openModal} className='btn-home-message'>
                                            Agendar Hora
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        ) : null}
                        {!currentSchedule && !availability ? (
                            <>
                                <Row>
                                    <Col className=' text-center'>
                                        <h1 className='fw-bold primary-color-text title-message'>Telemedicina</h1>
                                        <p className='primary-color-text subtitle-message'>
                                            {/* Te notificaremos cuando puedas agendar para tu próxima etapa <strong>Telemedicina</strong> */}
                                            {/* Nuestro médico te espera para <strong>comenzar tu tratamiento</strong> */}
                                            Para agendar tu consulta de telemedicina, <strong>solo presiona el botón de WhatsApp</strong> y estaremos listos para ayudarte.
                                        </p>
                                    </Col>
                                </Row>
                                {/* <Row className='mb-3'>
                                    <Col className=' text-center'>
                                        <Button disabled className='btn-home-message btn-homegray-message'>
                                            Cargando horas...
                                        </Button>
                                    </Col>
                                </Row> */}
                            </>
                        ) : null}
                        {currentSchedule ? (
                            <>
                                <Row>
                                    <Col className=' text-center primary-color-text'>
                                        <h1 className='title-message' ><strong>¡Telemedicina agendada con éxito!</strong></h1>
                                        <p className='primary-color-text subtitle-message'>
                                            Nuestro médico te atenderá el día
                                        </p>
                                        {/* <small>Asiste el día</small> */}
                                        <p className='p-success-schedule mt-2'> <strong>{formatDate(attentionDate)}</strong></p>
                                    </Col>
                                </Row>
                                <Row className='mb-3'>
                                    <Col className=' text-center'>
                                        <Link to="/agendar-hora-telemedicina" className='d-flex justify-content-center link'   >
                                            <Button className='btn-home-message'>
                                                Eliminar cita
                                            </Button>
                                        </Link>
                                    </Col>
                                </Row>
                            </>
                        ) : null}
                    </>
                )}
                {/* <Row className='pt-2'>
                    <Col className='d-flex justify-content-center'>
                        <div className='fw-bold py-2 primary-bg-color rounded-1 indicators-content canvas-message'>
                            Ya puedes revisar el resultado dee tu ECG
                        </div>
                    </Col>
                </Row> */}
            </div>
            <ShowModalSchedulePatient />
        </>
    );
}

export default HomeMessageMedismart;