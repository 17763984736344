import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Card, InputGroup } from 'react-bootstrap';
import LoginLoader from '../Loader/LoginLoader';
import ModalConsentError from 'happ-components/Modal/ModalInformedConsent/ModalConsentError';

export const CodeValidationConsent = ({ 
  onValidateCode, 
  reSendCode, 
  phone, 
  loading, 
  errors, 
  msgValidCode, 
  statusValidCode, 
  onServerError 
}) => {
  const [codeNumber, setCodeNumber] = useState('');
  const [reSendDisabled, setResendDisabled] = useState(false);
  const [remainingTime, setRemainingTime] = useState(60);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (errors.errorServer) {
      setShowModal(true);
    }
  }, [errors])
  

  const handleValidateCode = () => {
    onValidateCode({code:codeNumber});
  }
  
  const handleResendCode = () => {
    setResendDisabled(true);
    setRemainingTime(30);
    reSendCode();

    const timer = setInterval(() => {
      setRemainingTime(prevTime => {
        if (prevTime === 1) {
          clearInterval(timer);
          setResendDisabled(false);
          return 30;
        }
        return prevTime - 1;
      });
    }, 1000);
  }

  const modalErrorClose = () => {
    if (statusValidCode !== null && statusValidCode !== 400) {
      onServerError();
    } else {
      setShowModal(false);
    }
  }
  
  return (
    <Container className="mb-5">
      <Row className="justify-content-md-center ">
      {loading && <LoginLoader show={loading} />}
      {
        showModal &&
        <ModalConsentError 
          onClose={modalErrorClose}
          showModal={showModal}
          msgValidCode={msgValidCode}
          statusValidCode={statusValidCode} 
        />
      }  
        <Col md={6}>
          <Card className='m-3'>
            <Card.Title className='consent-card-title'>Validación de datos</Card.Title>
            <Card.Body>
              <div className="d-flex flex-row justify-content-start">
                <h6 className='consent-card-text mb-4'>Hemos enviado un código único al numero</h6>
                <h6 className='consent-card-text mb-4' style={{fontWeight:'bold', marginLeft:'2px'}}>+56{phone}</h6>
              </div>
              <div className="d-flex flex-row justify-content-start">
                <h6 className='consent-card-text mb-4'>Por favor, ingrésalo a continuación para confirmar tu consentimiento y completar el proceso.</h6>
              </div>
              <div className="d-flex flex-column justify-content-start">
                <h6 className='consent-card-samll-text'>Codigo enviado por SMS</h6>
              </div>
              <InputGroup className='consent-input-container'>
                <Form.Control 
                  size="sm" 
                  type="text" 
                  placeholder="1234" 
                  maxLength="4"
                  className='consent-input-text consent-input-text-validation-code'
                  onChange={(e)=> setCodeNumber(e.target.value)}
                   />
                <div className="d-flex justify-content-end">
                  <div class="btn consent-button"  onClick={handleValidateCode}>Continuar</div>
                </div>
              </InputGroup>
              {errors.errorServer && <p style={{ color: '#FA0F00', fontWeight: 'bold', fontSize:'12px', marginLeft:'4px' }}>{errors.errorServer}</p>}
              <div className="d-flex flex-column justify-content-start ">
                <h7 
                  onClick={handleResendCode} 
                  className='consent-link-text mt-4'
                  style={{ cursor: reSendDisabled ? 'default' : 'pointer' }}
                >{reSendDisabled 
                  ? <>Espera <span style={{ color: 'black', fontWeight:'bold' }}>{remainingTime}</span> segundos para solicitar otro código</>
                  : '¿No te ha llegado el código? Solicita otro'}
                </h7>
              </div>
            </Card.Body>
          </Card>
          
        </Col>
      </Row>
    </Container>
  );
};