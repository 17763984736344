import React from 'react';
import { useSelector } from 'react-redux';
import { DiagnosticModalIcon } from 'happ-icons/icons'
import { DiagnosticCalendarIcon } from 'happ-icons/icons'
import { CloseModalBlue } from 'happ-icons/icons'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const ModalSchedulePatient = (props) => {
    const { medicalStagePrint } = useSelector(state => state.auth);
    const { providerType } = useSelector(state => state.telehealthProvider);    
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            {/* este es para el colesterol plomo */}
            <div className='modal-content-white'>
                <Row className=''>
                    <Col className='mt-1'>
                        <div onClick={props.onHide} className='modal-close-btn primary-color-text'>
                            <CloseModalBlue
                            />
                        </div>
                        <div className='text-center'>
                            <DiagnosticModalIcon fill="#044064" className="my-2 ms-3" />
                            <h6 className='primary-color-text fw-bold'>
                                {/* {medicalStagePrint ? (
                                    <>
                                        {medicalStagePrint !== 'medical-teleconsultation' ? 'Exámenes Físicos' : 'Telemedicina'}
                                    </>
                                ) : 'Chequeo y diagnóstico'} */}
                                Agendamiento
                            </h6>
                        </div>
                    </Col>
                </Row>
                <Modal.Body style={{ padding: 0 }}>
                    <div>
                        <Row>
                            <Col>
                                {medicalStagePrint ? (
                                    <>
                                        {medicalStagePrint === 'welcome' ? (
                                            <ul className='primary-color-text mx-5'>
                                                <li className=' my-2'> En esta etapa <strong>detectaremos</strong> si tienes <strong>sospechas</strong> de alguna <strong>enfermedad cardiovascular</strong>. </li>
                                                <li className=' my-2'> Te realizaremos mediciones y <strong>exámenes no invasivos</strong>. </li>
                                                <li className=' my-2'> Si detectamos alguna sospecha, <strong>te informaremos que exámenes te debes realizar</strong>. </li>
                                            </ul>
                                        ) : medicalStagePrint === 'diagnostic-complementary-one' || medicalStagePrint === 'diagnostic-complementary-two' ? (
                                            <ul className='primary-color-text mx-5'>
                                                <li className=' my-2'> En esta etapa <strong>detectaremos</strong> si tienes <strong>sospechas</strong> de alguna <strong>enfermedad cardiovascular</strong>. </li>
                                                <li className=' my-2'> Te realizaremos mediciones y <strong>exámenes no invasivos</strong>. </li>
                                                <li className=' my-2'> Si detectamos alguna sospecha, <strong>te informaremos que exámenes te debes realizar</strong>. </li>
                                            </ul>
                                        ) : medicalStagePrint === 'confirmatory-diagnostic-complementary-one' ? (

                                            <ul className='primary-color-text mx-5'>
                                                <li className=' my-2'> En esta etapa <strong>revisaremos indicadores específicos</strong> para confirmar <strong>sospechas detectadas</strong> en la fase anterior.</li>
                                                <li className=' my-2'> Te realizaremos mediciones y <strong>exámenes de sangre</strong> de ser necesarios</li>
                                                <li className=' my-2'> Estos exámenes se utilizarán para la <strong>creación de tu eventual tratamiento</strong>.</li>
                                            </ul>
                                        ) : medicalStagePrint === 'physical-exams' ? (
                                            <ul className='primary-color-text mx-5'>
                                                <li className=' my-2'> En esta etapa mediremos la <strong>actividad eléctrica de tu corazón en reposo.</strong></li>
                                                <li className=' my-2'> Se realizará un <strong>procedimiento no invasivo</strong>, por lo que recomendamos asistir con <strong>ropa cómoda</strong>.</li>
                                                <li className=' my-2'> Si detectamos una sospecha o anomalía, <strong>nuestro medico Happ</strong> te indicará los pasos a seguir.</li>
                                            </ul>
                                        ) : medicalStagePrint === 'medical-teleconsultation' ? (
                                            <ul className='primary-color-text mx-5'>
                                                <li className=' my-2'> En esta etapa tu medico te dará el <strong>tratamiento</strong> para las diferentes <strong>patologías</strong> que te diagnosticamos.</li>
                                                <li className=' my-2'> Confirmaremos tu <strong>diagnostico</strong> y determinaremos <strong>tu riesgo cardiovascular</strong></li>
                                            </ul>
                                        ) : medicalStagePrint === 'nursing-teleconsultation-one' ? (
                                            <ul className='primary-color-text mx-5'>
                                                <li className=' my-2'> Nuestra <strong>enfermera Happ</strong> te entregara <strong>pautas fundamentales para tu salud</strong> </li>
                                                <li className=' my-2'> El objetivo es <strong>conocer tus preferencias</strong> para ajustar tu <strong>plan de mejora</strong>.</li>
                                                <li className=' my-2'> Te entregaremos un <strong>plan nutricional, plan deportivo y metas</strong> para el próximo control</li>
                                            </ul>
                                        ) : (
                                            medicalStagePrint === 'meta-exams-one' ||
                                            medicalStagePrint === 'meta-exams-two' ||
                                            medicalStagePrint === 'meta-exams-three') ? (
                                            <ul className='primary-color-text mx-5'>
                                                <li className=' my-2'> En esta etapa evaluaremos la evolución de tu <strong>tratamiento</strong>. </li>
                                                <li className=' my-2'> Te realizaremos mediciones y exámenes según tu riesgo cardiovascular.</li>
                                                <li className=' my-2'> Recuerda asistir con Ropa cómoda.</li>
                                            </ul>
                                        ) : medicalStagePrint === 'meta-sos' ? (
                                            <ul className='primary-color-text mx-5'>
                                                <li className=' my-2'> En esta etapa, nuestro médico Happ analizará en detalle tus últimos exámenes de control.</li>
                                                <li className=' my-2'> Recuerda ser puntual en la hora de conexión.</li>
                                                <li className=' my-2'> El link de la telemedicina te lo enviaremos a <strong>tu correo</strong>.</li>
                                            </ul>
                                        ) : null}
                                    </>
                                ) : <ul className='primary-color-text mx-5'>
                                    <li className=' my-2'> En esta etapa <strong>detectaremos</strong> si tienes <strong>sospechas</strong> de alguna <strong>enfermedad cardiovascular</strong>. </li>
                                    <li className=' my-2'> Te realizaremos mediciones y <strong>exámenes no invasivos</strong>. </li>
                                    <li className=' my-2'> Si <strong>detectamos</strong> alguna <strong>sospecha</strong>, te realizaremos <strong>exámenes de sangre para confirmar</strong>. </li>
                                </ul>}
                                <div className='py-3'>
                                    {medicalStagePrint ? (
                                        <>
                                            {medicalStagePrint !== 'medical-teleconsultation' ? (
                                                <Link to="/agendar-hora" className='d-flex justify-content-center link'>
                                                    <Button className='shadow btn-blue'>
                                                        <div className='d-flex justify-content-center mt-1'>
                                                            <DiagnosticCalendarIcon />
                                                            <p className='ms-3'>Agendar</p>
                                                        </div>
                                                    </Button>
                                                </Link>
                                            ) : (
                                                <Link to={`${providerType === 'medismart-schedule' ? '/agendar-hora-telemedicina' : '/agendar-hora'}`} className='d-flex justify-content-center link'>
                                                    <Button className='shadow btn-blue'>
                                                        <div className='d-flex justify-content-center mt-1'>
                                                            <DiagnosticCalendarIcon />
                                                            <p className='ms-3'>Agendar Telemedicina</p>
                                                        </div>
                                                    </Button>
                                                </Link>
                                            )}
                                        </>
                                    ) : (
                                        <Link to="/agendar-hora" className='d-flex justify-content-center link'>
                                            <Button className='shadow btn-blue'>
                                                <div className='d-flex justify-content-center mt-1'>
                                                    <DiagnosticCalendarIcon />
                                                    <p className='ms-3'>Agendar chequeo inicial</p>
                                                </div>
                                            </Button>
                                        </Link>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
            </div>
        </Modal>
    )
}

export default ModalSchedulePatient