import axiosClient from 'happ-config/axios';


const getExamHistory = async token => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'x-auth-token': token
        };
        const response = await axiosClient.get('/patients/getHistoryResults',{
            headers: headers
        });
        return response
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const getMyExamHistory = async (token, id) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'x-auth-token': token
        };
        const response = await axiosClient.get(`/patients/exams/${id}`);
        return response
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const getMedicalPrescriptions = async (token) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization':`Bearer ${token}`
        };
        const response = await axiosClient.get(`/medical-prescriptions/profile`, {headers});
        return response
    } catch (error) {
        const {response} = error;
        return response;
    }
}

export {
    getExamHistory,
    getMyExamHistory,
    getMedicalPrescriptions
}