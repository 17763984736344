import './styles.css';
import { Row, Col } from 'react-bootstrap';
import { HappContainer } from 'happ-components/HappStyledComponents/HappContainer/HappContainer';
import TimeLineSurvey from './TimeLineSurvey';
import SurveyDelivery from './SurveyDelivey';
import useCancerSurvey from 'happ-custom-hooks/useCancerSurvey/useCancerSurvey';

const fullSurveyState = {
    'cancer-cervix0': '',
    'cancer-colon0': '',
    'cancer-colon1': '',
    'cancer-mama0': '',
    'cancer-mama1': '',
    'cancer-pulmon0': '',
    'cancer-pulmon1': '',
    'cancer-pulmon2': '',
    'cancer-pulmon3': '',
    'cancer-pulmon4': '',
    'cancer-pulmon5': '',
    'cancer-pulmon6': '',
    'cancer-prostata0': '',
    'cancer-prostata1': '',
    'cancer-prostata2': '',
    'cancer-prostata3': '',
    'cancer-prostata4': '',
    'cancer-prostata5': '',
    'cancer-prostata6': '',
    'cancer-prostata7': '',
}

const CancerSurveys = () => {
    const { 
        surveyQuestions,
        handlerPreview, 
        handlerChangeAnswer,
        surveyError,
        submit,
        arrayStep,
        currentStep,
        isOm,
        derivations,
    } = useCancerSurvey(fullSurveyState);
    return (
        <Row className="mt-content">
                <HappContainer> 
                    <Col className='pt-2 mt-2'>
                        <div className='d-flex justify-content-center'>
                            <div className='text-center py-2 text-white primary-bg-color  rounded-1 indicators-content'>
                                <strong className='title-stages' >Encuestas Happ</strong>
                            </div>
                        </div>
                    </Col>
                    {submit ? (
                        <Row className='d-flex justify-content-center'>
                            <SurveyDelivery 
                                isOm={isOm}
                                derivations={derivations}
                            />
                        </Row>
                    ) : (
                        <>
                            <Col className='d-flex justify-content-center'>
                                <div className='mt-5 mb-3'>
                                    <div className="arrow-steps clearfix">
                                        {arrayStep && arrayStep.length ? (
                                            <>
                                                {arrayStep.map(step => (
                                                    <div
                                                        key={step} 
                                                        className={`step ${step === currentStep ? 'current' : ''}`}
                                                    ></div>
                                                ))}
                                            </>
                                        ) : null }
                                    </div>
                                </div>
                            </Col>
                            <Col md={8} sm={10} lg={8} className='justify-content-center mt-3 mb-3 content-surveys'>
                                {surveyQuestions && surveyQuestions.length ? (
                                    surveyQuestions.map((question, position) => (
                                        <TimeLineSurvey 
                                            key={position}
                                            questionObj={question}
                                            handlerChangeAnswer={handlerChangeAnswer}
                                            handlerPreview={handlerPreview}
                                            surveyError={surveyError}
                                        />
                                    ))
                                ) : null}
                            </Col>
                        </>
                    )}
                </HappContainer>
        </Row>
     );
}
 
export default CancerSurveys;