import React from 'react';
import { Container, Row, Col, Form, Card, InputGroup } from 'react-bootstrap';
import ModalConsentError from 'happ-components/Modal/ModalInformedConsent/ModalConsentError';

export const PersonalDataConsent = ({
  onContinue,
  phone,
  email,
  setPhone,
  setEmail,
  errors,
  onServerError
}) => {

  const modalErrorClose = () => {
    onServerError();
  }

  return (
    <Container className="mt-5 mb-5">
      <Row className="justify-content-md-center ">
        {errors.errorServer && <ModalConsentError onClose={modalErrorClose} />} 
        <Col md={6}>
          <Card className='m-3'>
            <Card.Title className='consent-card-title'>Necesitamos algunos datos</Card.Title>
            <Card.Body>
              <div className="d-flex flex-column justify-content-start">
                <h6 className='consent-card-text'>Para empezar, necesitamos tu número de teléfono móvil y tu correo electrónico.</h6>
                <h6 className="mt-1 consent-card-text">Utilizaremos tu teléfono para validar tu consentimiento y mantener contacto contigo.</h6>
              </div>
              <Form.Group controlId="customInput" className="mb-4 mt-4 ">
                <InputGroup >
                  <InputGroup.Text className='consent-input-text'>Teléfono Movil +56</InputGroup.Text>
                  <Form.Control 
                    type="text" 
                    placeholder="987654321" 
                    className='consent-input-text'
                    value={phone}
                    onChange={(e)=> setPhone(e.target.value)} 
                  />
                </InputGroup>
                {errors.phonePatient && <p style={{ color: '#FA0F00', fontWeight: 'bold', fontSize:'12px', marginLeft:'4px' }}>{errors.phonePatient}</p>}
                <InputGroup className='mt-4'>
                  <InputGroup.Text className='consent-input-text'>Correo electronico:</InputGroup.Text>
                  <Form.Control 
                    type="text" 
                    placeholder="happ@gmail.com" 
                    className='consent-input-text'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputGroup>
                {errors.emailPatient && <p style={{ color: '#FA0F00', fontWeight: 'bold', fontSize:'12px', marginLeft:'4px' }}>{errors.emailPatient}</p>}
              </Form.Group>
              <div className="d-flex justify-content-end mt-3 mb-2">
                <div className="btn consent-button"  onClick={onContinue}>Continuar</div>
              </div>
              {errors.errorServer && <p style={{ color: '#FA0F00', fontWeight: 'bold', fontSize:'12px', marginLeft:'4px' }}>{errors.errorServer}</p>}
            </Card.Body>
          </Card>
          
        </Col>
      </Row>
    </Container>
  );
};

