import {
  INIT_INFORMED_CONSENT,
  INIT_SEND_PATIENT_DATA,
  SEND_PATIENT_DATA,
  ERROR_SEND_PATIENT_DATA,
  INIT_INFORMED_CONSENT_PREVIEW,
  INFORMED_CONSENT_PREVIEW,
  ERROR_INFORMED_CONSENT_PREVIEW,
  INIT_CONFIRM_INFORMED_CONSENT,
  INFORMED_CONSENT_CONFIRMED,
  ERROR_INFORMED_CONSENT_CONFIRM,
  INIT_CONSENT_CODE_VALIDATION,
  CONSENT_CODE_VALIDATION,
  ERROR_CONSENT_CODE_VALIDATION,
  INIT_CONSENT_VALIDATE_CODE,
  CONSENT_CODE_VALIDATED,
  ERROR_CONSENT_VALIDATE_CODE,
  RESET_INFORMED_CONSENT,
  ERROR_RESET_INFORMED_CONSENT,
  LOGOUT,
} from 'happ-redux/happ-types';

import {
  sendPatientDataService,
  getPreviewPdfService,
  confirmConsentService,
  validateCodeService,
  resetInformedConsentService,
} from 'happ-redux/happ-services/informed-consent/informed-consent';

export function sendPatientDataAction(body){
  return async (dispatch) => {
    dispatch(initSendPatientData());
    const token = localStorage.getItem('token');
    try {
      const response = await sendPatientDataService(body, token);
      if (response.status === 200) {
        dispatch(sendPatientData({phone:response.data.phone, email:response.data.email}))     
      } else {
        dispatch(sendPatientDataError());
      }
    } catch (error) {
      dispatch(sendPatientDataError());
    }
  }
}

export function getPreviewPdfAction(){
  return async (dispatch) => {
    dispatch(initInformedConsentPreview());
    const token = localStorage.getItem('token');
    try {
      const response = await getPreviewPdfService(token);
      if (response.status === 200) {
        const blob = response.data;
        const objectUrl = URL.createObjectURL(blob);
        dispatch(informedConsentPreview({previewPdf:objectUrl}));
      } 
      else {
        dispatch(informedConsentPreviewError());
      }
    } catch(error) {
      dispatch(informedConsentPreviewError());
    }
  }}

  export function confirmConsentAction(){
    return async (dispatch) => {
      dispatch(initConfirmInformedConsent());
      const token = localStorage.getItem('token');
      try {
        const response = await confirmConsentService(token);
        if (response.status === 200) {
          dispatch(informedConsentConfirmed());
        } else {
          dispatch(informedConsentConfirmError());
        }
      } catch (error) {
        dispatch(informedConsentConfirmError());
      }
    }
  }

export function requestValidationCodeAction(){
  return async (dispatch) => {
    const token = localStorage.getItem('token');
    try {
      const response = await confirmConsentService(token);
      if (response.status=== 200) {
        dispatch(consentCodeValidation(response.data));
      } else {
        dispatch(errorConsentCodeValidation());
      }
    } catch (error) {
      dispatch(errorConsentCodeValidation());
    }
  }
}


export function validateCodeAction(body){
  return async (dispatch) => {
    dispatch(initConsentCodeValidation());
    const token = localStorage.getItem('token');
    try {
      const response = await validateCodeService(body, token);
      if (response.status === 200) {
        dispatch(consentCodeValidated());
      } else if (response.status === 400) {
        dispatch(errorConsentValidateCode({
          error: 
            'El código de verificación es inválido. Verifique que haya escrito correctamente, o solicite uno nuevo.',
          statusValidCode: 400,  
        }));
      } else {
        dispatch(errorConsentValidateCode({
          error: 
            'Ha ocurrido un error en el proceso, de momento podras acceder a la plataforma pero no podras realizar ninguna accion hasta que aceptes los terminos de consentimiento informado',
          statusValidCode: 500,   
        }));
      }
    } catch (error) {
      dispatch(errorConsentValidateCode());
    }
  }
}

export function resetInformedConsentAction(){
  return async (dispatch) => {
    const token = localStorage.getItem('token');
    try{
    
     const response = await resetInformedConsentService(token);
     if (response.status === 200) {
       dispatch(resetInformedConsent());
     } else {
       dispatch(resetInformedConsentError());
     }
     } catch (error) {
       dispatch(resetInformedConsentError());
     }
  }
}

const initSendPatientData = () => ({
  type: INIT_SEND_PATIENT_DATA
});

const sendPatientData = response => ({
  type: SEND_PATIENT_DATA,
  payload: response
});

const sendPatientDataError = error => ({
  type: ERROR_SEND_PATIENT_DATA,
  payload: error
});

const initInformedConsentPreview = () => ({
  type: INIT_INFORMED_CONSENT_PREVIEW
});

const informedConsentPreview = response => ({
  type: INFORMED_CONSENT_PREVIEW,
  payload: response
});

const informedConsentPreviewError = error => ({
  type: ERROR_INFORMED_CONSENT_PREVIEW,
  payload: error
});

const initConfirmInformedConsent = () => ({
  type: INIT_CONFIRM_INFORMED_CONSENT
});

const informedConsentConfirmed = () => ({
  type: INFORMED_CONSENT_CONFIRMED
});

const informedConsentConfirmError = error => ({
  type: ERROR_INFORMED_CONSENT_CONFIRM,
  payload: error
});

const initConsentCodeValidation = () => ({
  type: INIT_CONSENT_CODE_VALIDATION
});

const consentCodeValidation = response => ({
  type: CONSENT_CODE_VALIDATION,
  payload: response
});

const errorConsentCodeValidation = error => ({
  type: ERROR_CONSENT_CODE_VALIDATION,
  payload: error
});

const consentCodeValidated = () => ({
  type: CONSENT_CODE_VALIDATED,
});

const errorConsentValidateCode = error => ({
  type: ERROR_CONSENT_VALIDATE_CODE,
  payload: error
});

const resetInformedConsent = () => ({
  type: RESET_INFORMED_CONSENT
});

const resetInformedConsentError = error => ({
  type: ERROR_RESET_INFORMED_CONSENT,
  payload: error
});





const logout = () => ({
  type: LOGOUT
});

