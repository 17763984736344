import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ConfirmConsent } from 'happ-components/ConsentConfirmationParts/ConfirmRejectConsent';
import { PersonalDataConsent } from 'happ-components/ConsentConfirmationParts/PersonalDataInput';
import { CodeValidationConsent } from 'happ-components/ConsentConfirmationParts/CodeValidationInput';
import { ValidatedConsent } from 'happ-components/ConsentConfirmationParts/ConsentValidated';
import useAuth from 'happ-custom-hooks/useAuth/useAuth';

import {
  sendPatientDataAction,
  getPreviewPdfAction,
  confirmConsentAction,
  requestValidationCodeAction,
  validateCodeAction,
  resetInformedConsentAction,
} from 'happ-redux/happ-actions/informedConsent/informedConsentActions';

const useInformedConsent = (initialState) => {
  const [phonePatient, setPhonePatient] = useState('');
  const [emailPatient, setEmailPatient] = useState('');
  const [errors, setErrors] = useState({ phonePatient: '', emailPatient: '', codeError: '', errorServer: '' });
  const [state, setState] = useState(initialState);
  const dispatch = useDispatch();

  const { profile } = useAuth()
  const { currentStep, errorServer, previewPdf, loading, msgValidCode, statusValidCode } = useSelector(state => state.informedConsent);

  useEffect(() => {
    if (profile && profile.phone) {
      const phone = profile.phone.split(' ')[1];
      setPhonePatient(phone);
    }
    if (profile && profile.email) {
      setEmailPatient(profile.email);
    }
  }, [profile]);

  useEffect(() => {
    if (currentStep === 'confirm-preview') {
      dispatch(getPreviewPdfAction());
    }
    setState({
      ...state,
      currentStep,
    });
  }, [currentStep]);

  useEffect(() => {
    if (errorServer) {
      setErrors({ errorServer});
    }
  }, [errorServer]);  

  //*----View logic----//
  const stepStructure = currentStep => {
    switch(currentStep) {
        case "personal-data":
            return (
                <PersonalDataConsent
                    onContinue={handleContinue}
                    phone={phonePatient}
                    email={emailPatient}
                    setPhone={setPhonePatient}
                    setEmail={setEmailPatient}
                    errors={errors}
                    onServerError={onServerError}
                />
            );
        case "confirm-preview":
            return (
                <ConfirmConsent 
                    onAccept={handleConfirmConsent}
                    onReject={onRejectConsent}
                    samplePDF={previewPdf}
                    loading={loading}
                    errors={errors}
                    onServerError={onServerError}
                />
            ); 
        case "code-validation":
            return (
                <CodeValidationConsent 
                    onValidateCode={handleValidateCode}
                    reSendCode={reSendValidationCode}
                    phone={phonePatient}
                    loading={loading}
                    errors={errors}
                    msgValidCode={msgValidCode}
                    statusValidCode={statusValidCode}
                    onServerError={onServerError}
                />
            );
        case "consent-validated":    
            return (
                <ValidatedConsent 
                    onFinish={onFinish}
                />
            );    
        default: 
            return null;
    }
  }
  //!----View logic end----//


  //*----Personaldata component logic----//
  const handleContinue = () => {
    const emailError = isValidEmail(emailPatient) ? '' : 'Email invalido';
    const phoneError = isValidPhone(phonePatient) ? '' : 'Telefono invalido';

    if (emailError || phoneError) {
      setErrors({ emailPatient: emailError, phonePatient: phoneError });
      return;
    }


    dispatch(sendPatientDataAction({ email: emailPatient, phone: `56 ${phonePatient}`}));
  }

  const isValidEmail = (emailPatient) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(emailPatient);
  };
  
  const isValidPhone = (phonePatient) => {
    //TODO agregar validacion de telefono
    const phoneRegex = /^9\d{8}$/;
    return phoneRegex.test(phonePatient);
  };
  //!----Personaldata component logic end----//



  //*----ConfirmConsent component logic----//
  const handleConfirmConsent = () => {
    dispatch(confirmConsentAction());
  }

  const onRejectConsent = () => {
    dispatch(resetInformedConsentAction());
    const url = '/login';
    window.location.href = url;
  }
  //!----ConfirmConsent component logic end----//



  //*----CodeValidation component logic----//
  const handleValidateCode = (body) => {
    dispatch(validateCodeAction(body));
  }

  const reSendValidationCode = () => {
    dispatch(requestValidationCodeAction());
  }
  //!----CodeValidation component logic end----//



  //*----ConsentValidated component logic----//
  const onFinish = () => {
    const url = '/';
    window.location.href = url;
  }
  //!----ConsentValidated component logic end----//


  const onServerError = () => {
    const url = '/';
    window.location.href = url;
  }


  return {
    setPhonePatient,
    setEmailPatient,
    currentStep,
    emailPatient,
    phonePatient,
    errors,
    state,
    stepStructure,
  };
};

export default useInformedConsent;