import React from 'react';
import { Button, Modal } from 'react-bootstrap'

const ModalConsentReject = ({ isOpen, onReturn, onReject }) => {

  const rejectText = 'Si rechazas el consentimiento informado no tendras acceso a la plataforma\n Estas seguro que deseas rechazarlo ?';

  return (
    <Modal className='modal-confirm-consent' show={isOpen} onHide={onReturn} centered>
      <Modal.Header closeButton>
        <Modal.Title>Alerta</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ fontWeight: 'bold' }}>
        {rejectText}
      </Modal.Body>
      <Modal.Footer>
        <Button 
          onClick={onReturn}
          className='primary-bg-color'
        >
          Volver
        </Button>
        <Button variant="danger" onClick={onReject}>
          Rechazar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalConsentReject;