import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
//components
import { HappContainer } from 'happ-components/HappStyledComponents/HappContainer/HappContainer';

//sub-Views
import HomeMessageMedismart from 'happ-components/HomeMessage/HomeMessageMedismart';
import Indicators from 'happ-components/Indicators/Indicators';
import { Stage1 } from 'happ-icons/icons';
import { Stage1Agendada } from 'happ-icons/icons';
import { Stage2 } from 'happ-icons/icons';
import { Stage3 } from 'happ-icons/icons';
import { ControlFinal } from 'happ-icons/icons';
import { Stage5 } from 'happ-icons/icons';
import { Stage10 } from 'happ-icons/icons';
import { Sos, SosSchedule } from 'happ-icons/icons';
import useAuth from 'happ-custom-hooks/useAuth/useAuth';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useSchedule from 'happ-custom-hooks/useSchedule/useSchedule';
import { medicalStageDeliveryAction } from 'happ-redux/happ-actions/auth/authActions';
import { Stage5Agendado } from 'happ-icons/icons';
import LoaderView from 'happ-components/Loader/LoaderView';
import HomeScheduleDateInfo from 'happ-views/MedicalSchedule/HomeScheduleDateInfo';
import { checkTelehealthProviderAction } from 'happ-redux/happ-actions/telehealthProvider/telehealthProviderAction';
import { getMedicalPrescriptionsAction } from 'happ-redux/happ-actions/examHistory/examHistoryActions';


const Home = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(true);
    const { medicalStage, medicalStageId, profile, id } = useAuth();
    const { statusCodeSchedule } = useSchedule();
    const [medicalStageObj, setMedicalStageObj] = useState(null);
    const { providerType } = useSelector(state => state.telehealthProvider);    
    const {
        medicalStageResults,
        isDislipidemia,
        medicalStagePrint,
        lastPrescription,
        lastConsultation,
        otherExams,
        ecgDocument,
        isMetaExams,
        allHealthy,
        isLabConditional,
        companyName,
        medicalOrder,
    } = useSelector(state => state.auth);
    
    useEffect(() => {
        const init = () => {
            if (profile !== null) {
                if (id !== null && medicalStageId !== null) {
                    dispatch(medicalStageDeliveryAction(id, medicalStageId, navigate));
                    dispatch(checkTelehealthProviderAction(medicalStage, profile?.branchOfficeId?._id));
                    dispatch(getMedicalPrescriptionsAction());
                    setTimeout(()=> {
                        setLoading(false);
                    }, 2300)
                }
            }
        }
        init();
    }, [profile, id]);

    useEffect(() => {
        if (medicalStagePrint !== null) {
            const info = medicalStageInfo(medicalStagePrint, statusCodeSchedule);
            if (info && info.name === 'Electrocardiograma') {
                if (ecgDocument === null) {
                    info.name = 'Chequeo y diagnóstico';
                }
            }
            setMedicalStageObj(info);
        }
      }, [ecgDocument, medicalStagePrint, statusCodeSchedule])


    const medicalStageInfo = (currentMedicalStage, statusCodeSchedule) => {
        const options = {
            'welcome': {
                name: 'Bienvenida',
                icon: !statusCodeSchedule ? <Stage1 className='stages'/> : <Stage1Agendada className='stages' />
            },
            'diagnostic-complementary-one': {
                name: 'Chequeo y diagnóstico',
                icon: !statusCodeSchedule ? <Stage2 className='stages' /> : <Stage3 className='stages' />,
            },
            'confirmatory-diagnostic-complementary-one': {
                name: 'Chequeo y diagnóstico',
                icon: !statusCodeSchedule ? <Stage2 className='stages' /> : <Stage3 className='stages' />,
            },
            'medical-teleconsultation': {
                name: 'Telemedicina',
                icon: !statusCodeSchedule ? <Stage5 className='stages mt-3' /> : <Stage5Agendado className='stages mt-3' />,
            },
            'diagnostic-complementary-two': {
                name: 'Sigue cuidándote',
                icon: !statusCodeSchedule ? <Stage10 className='stages mt-3' /> : <Stage10 className='stages mt-3' />,
            },
            'meta-exams-one': {
                name: 'Exámenes de Control',
                icon: !statusCodeSchedule ? <ControlFinal className='stages mt-3' /> : <ControlFinal className='stages mt-3' />,
            },
            'meta-exams-two': {
                name: 'Exámenes de Control',
                icon: !statusCodeSchedule ? <ControlFinal className='stages mt-3' /> : <ControlFinal className='stages mt-3' />,
            },
            'meta-exams-three': {
                name: 'Exámenes de Control',
                icon: !statusCodeSchedule ? <ControlFinal className='stages mt-3' /> : <ControlFinal className='stages mt-3' />,
            },
            'meta-exams-two-one': {
                name: 'Exámenes de Control',
                icon: !statusCodeSchedule ? <ControlFinal className='stages mt-3' /> : <ControlFinal className='stages mt-3' />,
            },
            'meta-sos': {
                name: 'Exámenes de Control',
                icon: !statusCodeSchedule ? <ControlFinal className='stages mt-3' /> : <ControlFinal className='stages mt-3' />,
            },
            'sos': {
                name: 'Telemedicina de Control',
                icon: !statusCodeSchedule ? <Sos className='stages mt-3' /> : <SosSchedule className='stages mt-3' />,
            },
        }
        return options[currentMedicalStage] || null;
    }
    const renderView = (
        <>
            <Col className='pt-2'>
                <div className='d-flex justify-content-center'>
                    {medicalStageObj && medicalStageObj.name ? (
                        <div className={`text-center px-5 py-2 text-white primary-bg-color  rounded-1 indicators-content`}>
                            <strong className='title-stages' >
                                {medicalStageObj.name}
                            </strong>
                        </div>
                    ) : null}
                </div>
            </Col>
            <Col sm={12} lg={12} md={12} className="mt-3 mb-4 col-img-stage">
                {medicalStageObj && medicalStageObj.icon}
            </Col>
            <Col sm={12} lg={12} md={12} className="mb-5">
                {providerType !== null ? (
                    <>
                        {providerType === '' || providerType === 'happ-schedule' ? (
                            <HomeScheduleDateInfo 
                                medicalStagePrint={medicalStagePrint}
                            />
                        ) : null}
                        {providerType === 'medismart-schedule' ? (
                            <HomeMessageMedismart />
                        ) : null}
                    </>
                ) : null}
                <Indicators
                    medicalStage={medicalStagePrint}
                    medicalStageResults={medicalStageResults}
                    isDislipidemia={isDislipidemia}
                    lastPrescription={lastPrescription}
                    lastConsultation={lastConsultation}
                    otherExams={otherExams}
                    ecgDocument={ecgDocument}
                    isMetaExams={isMetaExams}
                    allHealthy={allHealthy}
                    companyName={companyName}
                    isLabConditional={isLabConditional}
                    medicalOrder={medicalOrder}
                />
            </Col>
        </>
    )
    return (
        <Row className="mt-content ">
            <HappContainer>
                <>
                    {
                        isLoading ? (
                        <div className='text-center mt-5 mb-5'>
                            <LoaderView
                                animation="border"
                            />
                        </div>
                    ) : renderView}
                </>
            </HappContainer>
        </Row>
    );
}

export default Home;