import { SURVEYQUESTIONS } from "./questionsSurveysStructure";

function manSurveyOptions(age) {
    const surveys = [];
    const surveysCode = {};
    const arraySurveysCode = [];
    if (age > 40) {
        SURVEYQUESTIONS["cancer-colon"].map((question, position) => {
            surveys.push({
                question,
                code: `cancer-colon${position}`,
                typeCancer: 'cancer-colon',
                currentQuestion: false, 
                blockQuestion: false,
            });
            surveysCode[`cancer-colon${position}`] = '';
            arraySurveysCode.push(`cancer-colon${position}`);
        });
        SURVEYQUESTIONS["cancer-prostata"].map((question, position) => {
            surveys.push({
                question,
                code: `cancer-prostata${position}`,
                typeCancer: 'cancer-prostata',
                currentQuestion: false, 
                blockQuestion: false,
            });
            surveysCode[`cancer-prostata${position}`] = '';
            arraySurveysCode.push(`cancer-prostata${position}`);
        });
    }
    if (age > 50) {
        SURVEYQUESTIONS["cancer-pulmon"].map((question, position) => {
            surveys.push({
                question,
                code: `cancer-pulmon${position}`,
                typeCancer: 'cancer-pulmon',
                currentQuestion: false, 
                blockQuestion: false,
            });
            surveysCode[`cancer-pulmon${position}`] = '';
            arraySurveysCode.push(`cancer-pulmon${position}`);
        });
    }
    surveys[0].currentQuestion = true;
    return { surveys, surveysCode, arraySurveysCode };
};

function womenSurveyOptions(age) {
    const surveys = [];
    const surveysCode = {};
    const arraySurveysCode = [];
    if (age >= 40 && age <= 75) {
        SURVEYQUESTIONS["cancer-mama-a"].map((question, position) => {
            surveys.push({
                question,
                code: `cancer-mama${position}`,
                typeCancer: 'cancer-mama-a',
                currentQuestion: false,
                blockQuestion: false,

            });
            surveysCode[`cancer-mama${position}`] = '';
            arraySurveysCode.push(`cancer-mama${position}`);
        });
    } else {
        SURVEYQUESTIONS["cancer-mama-b"].map((question, position) => {
            surveys.push({
                question,
                code: `cancer-mama${position}`,
                typeCancer: 'cancer-mama-b',
                currentQuestion: false,
                blockQuestion: false, 
            });
            surveysCode[`cancer-mama${position}`] = '';
            arraySurveysCode.push(`cancer-mama${position}`);
        });
    }
    if (age >= 25 && age <= 65) {
        SURVEYQUESTIONS["cancer-cervix"].map((question, position) => {
            surveys.push({
                question,
                code: `cancer-cervix${position}`,
                typeCancer: 'cancer-cervix',
                currentQuestion: false,
                blockQuestion: false, 
            });
            surveysCode[`cancer-cervix${position}`] = '';
            arraySurveysCode.push(`cancer-cervix${position}`);
        });
    }
    if (age > 50) {
        SURVEYQUESTIONS["cancer-pulmon"].map((question, position) => {
            surveys.push({
                question,
                code: `cancer-pulmon${position}`,
                typeCancer: 'cancer-pulmon',
                currentQuestion: false, 
                blockQuestion: false, 

            });
            surveysCode[`cancer-pulmon${position}`] = '';
            arraySurveysCode.push(`cancer-pulmon${position}`);
        });
    }
    if (age > 40) {
        SURVEYQUESTIONS["cancer-colon"].map((question, position) => {
            surveys.push({
                question,
                code: `cancer-colon${position}`,
                typeCancer: 'cancer-colon',
                currentQuestion: false, 
                blockQuestion: false, 
            });
            surveysCode[`cancer-colon${position}`] = '';
            arraySurveysCode.push(`cancer-colon${position}`);
        });
    }
    surveys[0].currentQuestion = true;
    return { surveys, surveysCode, arraySurveysCode };
};

function activeNextQuestion(currentQuestion, surveyQuestions, arrayBlockQuestions) {
    const indexCurrentQuestion = surveyQuestions.findIndex((obj) => obj.code === currentQuestion);
    let nextQuestion = '';
    if ((indexCurrentQuestion + 1) === surveyQuestions.length) {
        nextQuestion = currentQuestion;
        return {
            newSurveyQuestions: surveyQuestions,
            finishSurveyQuestion: true,
            nextQuestion,
        };
    } else {
        if (arrayBlockQuestions.length) {
            const arrayQuestions = surveyQuestions.map((obj) => {
                if (obj.currentQuestion === true) {
                    obj.currentQuestion = false;
                }
                if (arrayBlockQuestions.includes(obj.code)) {
                    obj.blockQuestion = true;
                }
                return obj;
            });
            if ((indexCurrentQuestion + 1) === (arrayQuestions.length - 1)) {
                if (arrayQuestions[arrayQuestions.length - 1].blockQuestion === true) {
                    arrayQuestions[indexCurrentQuestion].currentQuestion = true;
                    nextQuestion = arrayQuestions[indexCurrentQuestion].code;
                    return {
                        newSurveyQuestions: arrayQuestions,
                        finishSurveyQuestion: true,
                        nextQuestion,
                    };
                } else {
                    const lastIndexBlockQuestion = arrayQuestions.findLastIndex((obj) => obj.blockQuestion);
                    const updateQuestions = arrayQuestions.map((obj, position) => {
                        const nextPosition = Number(lastIndexBlockQuestion) + 1;
                        if (lastIndexBlockQuestion < arrayQuestions.length) {
                            if (position === nextPosition) {
                                obj.currentQuestion = true;
                                nextQuestion = obj.code;
                            }
                        }
                        return obj;
                    });
                    return {
                        newSurveyQuestions: updateQuestions,
                        finishSurveyQuestion: false,
                        nextQuestion,
                    };
                }
            }
            const lastIndexBlockQuestion = arrayQuestions.findLastIndex((obj) => obj.blockQuestion);
            const updateQuestions = arrayQuestions.map((obj, position) => {
                const nextPosition = Number(lastIndexBlockQuestion) + 1;
                if (lastIndexBlockQuestion < arrayQuestions.length) {
                    if (position === nextPosition) {
                        obj.currentQuestion = true;
                        nextQuestion = obj.code;
                    }
                }
                return obj;
            });
            return {
                newSurveyQuestions: updateQuestions,
                finishSurveyQuestion: false,
                nextQuestion,
            };
        } else {
            const currentQuestionIndex = surveyQuestions.findIndex((obj) => obj.currentQuestion);
            const arrayQuestions = surveyQuestions.map((obj, position) => {
                if (obj.currentQuestion === true) {
                    obj.currentQuestion = false;
                }
                const nextPosition = Number(currentQuestionIndex) + 1;
                if (nextPosition < surveyQuestions.length) {
                    if (position === nextPosition) {
                        obj.currentQuestion = true;
                        nextQuestion = obj.code;
                    }
                }
                return obj;
            });
            return {
                newSurveyQuestions: arrayQuestions,
                finishSurveyQuestion: false,
                nextQuestion,
            };
        }
    }
};

function checkLungAnswers(initQuestion, endQuestion, allAnswers) {
    let affirmativeAnswers = 0;
    while(initQuestion <= endQuestion) {
        if (allAnswers[`cancer-pulmon${initQuestion}`] === 'Si') {
            affirmativeAnswers ++;
        }
        initQuestion ++;
    }
    if (affirmativeAnswers > 0) {
        return true;
    }
    return false;
}

export function activePreviewQuestion(surveyQuestions, currentQuestionCode) {
    let activePreviewCode = '';
    let nextQuestion = '';
    const indexCurrentQuestionCode = surveyQuestions.findIndex((obj) => obj.code === currentQuestionCode);
    if (indexCurrentQuestionCode > 0) {
        for (let index = indexCurrentQuestionCode - 1; index >= 0; index --) {
            if (surveyQuestions[index].blockQuestion === false) {
                activePreviewCode = surveyQuestions[index].code;
                break;
            }
        }
        const newSurveyQuestions = surveyQuestions.map((obj) => {
            if (obj.currentQuestion === true) {
                obj.currentQuestion = false;
            }
            if (obj.code === activePreviewCode) {
                obj.currentQuestion = true;
            }
            nextQuestion = activePreviewCode;
            return obj;
        });
        return { newSurveyQuestions, nextQuestion }
    } else {
        nextQuestion = currentQuestionCode;
        return { newSurveyQuestions: surveyQuestions, nextQuestion }; 
    }
};

export function cancerMamaFlowV1(currentQuestion, currentAnswer, surveyQuestions) {
    switch (currentQuestion) {
        case 'cancer-mama0':
            if (currentAnswer === 'Si') {
                 return {
                    action: 'finish',
                    results: {
                        typeCancer: 'cancer-mama',
                        om: false,
                        msg: ''
                    },
                    arrayBlockQuestions: [],
                    updateSurveyQuestionObj: activeNextQuestion(currentQuestion, surveyQuestions, []),
                 } 
            } else { 
                return {
                    action: 'finish',
                    results: {
                        typeCancer: 'cancer-mama',
                        om: true,
                        msg: 'recomendar chequearse y OM Mamografía'
                    },
                    arrayBlockQuestions: [],
                    updateSurveyQuestionObj: activeNextQuestion(currentQuestion, surveyQuestions, []),
                } 
            }
        default:
            return {};
    }
};

export function cancerMamaFlowV2(currentQuestion, currentAnswer, surveyQuestions) {
    switch (currentQuestion) {
        case 'cancer-mama0':
            if (currentAnswer === 'Si') {
                return {
                    action: 'continue',
                    results: {},
                    arrayBlockQuestions: [],
                    updateSurveyQuestionObj: activeNextQuestion(currentQuestion, surveyQuestions, []),
                }
            } else {
                return {
                    action: 'finish',
                    results: {
                        typeCancer: 'cancer-mama',
                        om: false,
                        msg: ''
                    },
                    updateSurveyQuestionObj: activeNextQuestion(currentQuestion, surveyQuestions, ['cancer-mama1']),
                }
            }
        case 'cancer-mama1':  
            if (currentAnswer === 'Si') {
                return {
                    action: 'finish',
                    results: {
                        typeCancer: 'cancer-mama',
                        om: false,
                        msg: ''
                    },
                    arrayBlockQuestions: [],
                    updateSurveyQuestionObj: activeNextQuestion(currentQuestion, surveyQuestions, []),
                }
            } else {
                return {
                    action: 'finish',
                    results: {
                        typeCancer: 'cancer-mama',
                        om: true,
                        msg: ''
                    },
                    arrayBlockQuestions: [],
                    updateSurveyQuestionObj: activeNextQuestion(currentQuestion, surveyQuestions, []),
                }
            }      
        default:
            return {};
    }
};

export function cancerCervixFlow(currentQuestion, currentAnswer, surveyQuestions) {
    switch (currentQuestion) {
        case 'cancer-cervix0':
            if (currentAnswer === 'Si') {
                return {
                    action: 'finish',
                    results: {
                        typeCancer: 'cancer-cervix',
                        om: false,
                        msg: ''
                    },
                    updateSurveyQuestionObj: activeNextQuestion(currentQuestion, surveyQuestions, []),
                }
            } else {
                return {
                    action: 'finish',
                    results: {
                        typeCancer: 'cancer-cervix',
                        om: true,
                        msg: 'Recomendar chequearse c/año'
                    },
                    updateSurveyQuestionObj: activeNextQuestion(currentQuestion, surveyQuestions, []),
                }
            }
        default:
            return null;
    }
};

export function cancerColonFlow(currentQuestion, currentAnswer, surveyQuestions) {
    switch (currentQuestion) {
        case 'cancer-colon0':
            if (currentAnswer === 'Si') { 
                return {
                    action: 'continue',
                    results: {},
                    updateSurveyQuestionObj: activeNextQuestion(currentQuestion, surveyQuestions, []),
                } 
            } else { 
                return {
                    action: 'finish',
                    results: {
                        typeCancer: 'cancer-colon',
                        om: false,
                        msg: ''
                    },
                    updateSurveyQuestionObj: activeNextQuestion(currentQuestion, surveyQuestions, ['cancer-colon1']),
                } 
            }
        case 'cancer-colon1':
            if (currentAnswer === 'Si') { 
                return {
                    action: 'finish',
                    results: {
                        typeCancer: 'cancer-colon',
                        om: false,
                        msg: 'Segun tu información, tienes una situación de riesgo mas elevado por antecdentes famliares, te recomendamos no dejar pasar mas de 5 años sin hacerte una colonoscopía preventva.'
                    },
                    updateSurveyQuestionObj: activeNextQuestion(currentQuestion, surveyQuestions, []),
                } 
            } else { 
                return {
                    action: 'finish',
                    results: {
                        typeCancer: 'cancer-colon',
                        om: true,
                        msg: 'Recomendar chequearse c/año y OM colonoscopía'
                    },
                    updateSurveyQuestionObj: activeNextQuestion(currentQuestion, surveyQuestions, []),
                } 
            }
        default:
            return {};
    }
};

export function cancerLungFlow(currentQuestion, currentAnswer, surveyQuestions, allAnswers){
    switch (currentQuestion) {
        case 'cancer-pulmon0':
            if (currentAnswer === 'Si') { 
                return {
                    action: 'continue',
                    results: {},
                    arrayBlockQuestions: [],
                    updateSurveyQuestionObj: activeNextQuestion(currentQuestion, surveyQuestions, []),
                } 
            } else { 
                return {
                    action: 'continue',
                    results: {},
                    arrayBlockQuestions: [],
                    updateSurveyQuestionObj: activeNextQuestion(currentQuestion, surveyQuestions, []),
                } 
            }
        case 'cancer-pulmon1':
            if (currentAnswer === 'Si') { 
                return {
                    action: 'continue',
                    results: {},
                    arrayBlockQuestions: [],
                    updateSurveyQuestionObj: activeNextQuestion(currentQuestion, surveyQuestions, []),
                } 
            } else { 
                return {
                    action: 'continue',
                    results: {},
                    arrayBlockQuestions: [],
                    updateSurveyQuestionObj: activeNextQuestion(currentQuestion, surveyQuestions, []),
                } 
            }
        case 'cancer-pulmon2':
            if (currentAnswer === 'Si') { 
                return {
                    action: 'continue',
                    results: {},
                    arrayBlockQuestions: [],
                    updateSurveyQuestionObj: activeNextQuestion(currentQuestion, surveyQuestions, []),
                } 
            } else { 
                return {
                    action: 'continue',
                    results: {},
                    arrayBlockQuestions: [],
                    updateSurveyQuestionObj: activeNextQuestion(currentQuestion, surveyQuestions, []),
                } 
            }
        case 'cancer-pulmon3':
            const checkAffirmativeAnswers = checkLungAnswers(0, 3, allAnswers);
            const arrayBlockQuestions = checkAffirmativeAnswers ? [] : ['cancer-pulmon4'];
            if (currentAnswer === 'Si') { 
                return {
                    action: 'continue',
                    results: {},
                    arrayBlockQuestions: [],
                    updateSurveyQuestionObj: activeNextQuestion(currentQuestion, surveyQuestions, arrayBlockQuestions),
                } 
            } else { 
                return {
                    action: checkAffirmativeAnswers ? 'continue' : 'finish',
                    results: checkAffirmativeAnswers ? {} : {
                        typeCancer: 'cancer-pulmon',
                        om: false,
                        msg: ''
                    },
                    arrayBlockQuestions: [],
                    updateSurveyQuestionObj: activeNextQuestion(currentQuestion, surveyQuestions, arrayBlockQuestions),
                } 
            }
        case 'cancer-pulmon4':
            if (currentAnswer === 'Si') { 
                return {
                    action: 'finish',
                    results: {
                        typeCancer: 'cancer-pulmon',
                        om: false,
                        msg: ''
                    },
                    arrayBlockQuestions: [],
                    updateSurveyQuestionObj: activeNextQuestion(currentQuestion, surveyQuestions, []),
                } 
            } else { 
                return {
                    action: 'finish',
                    results: {
                        typeCancer: 'cancer-pulmon',
                        om: true,
                        msg: ''
                    },
                    arrayBlockQuestions: [],
                    updateSurveyQuestionObj: activeNextQuestion(currentQuestion, surveyQuestions, []),
                } 
            }
        default:
            return {};
    }
};

export function cancerProstateFlow(currentQuestion, currentAnswer, surveyQuestions, age){
    switch (currentQuestion) {
        case 'cancer-prostata0':
            if (currentAnswer === 'Si') {
                return {
                    action: 'continue',
                    results: {},
                    arrayBlockQuestions: [],
                    updateSurveyQuestionObj: activeNextQuestion(currentQuestion, surveyQuestions, []),
                }
            } else {
                const action = age > 50 ? 'continue' : 'finish';
                const arrayBlockQuestions = age > 50 ? [] : ['cancer-prostata1'];
                const results = age > 50 ? {} : {
                    typeCancer: 'cancer-prostata',
                    om: false,
                    msg: ''
                }
                return {
                    action,
                    results,
                    updateSurveyQuestionObj: activeNextQuestion(currentQuestion, surveyQuestions, arrayBlockQuestions),
                }
            }
        case 'cancer-prostata1':
            if (currentAnswer === 'Si') {
                return {
                    action: 'finish',
                    results: {
                        typeCancer: 'cancer-prostata',
                        om: false,
                        msg: ''
                    },
                    arrayBlockQuestions: [],
                    updateSurveyQuestionObj: activeNextQuestion(currentQuestion, surveyQuestions, []),
                }
            } else {
                return {
                    action: 'finish',
                    results: {
                        typeCancer: 'cancer-prostata',
                        om: true,
                        msg: ''
                    },
                    updateSurveyQuestionObj: activeNextQuestion(currentQuestion, surveyQuestions, []),
                }
            }    
        default:
            return {};
        }; 
};

export function showAvailableSurveys(age, gender) {
    const surveys = gender === 'M' ? manSurveyOptions(age) : womenSurveyOptions(age);
    return surveys;
} 