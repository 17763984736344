import {
    INIT_MEDICAL_SCHEDULE,
    MEDICAL_SCHEDULE,
    ERROR_MEDICAL_SCHEDULE,
    LOGOUT,
    INIT_GET_MEDICAL_SCHEDULES_BY_BRANCH_PATIENT,
    GET_MEDICAL_SCHEDULES_BY_BRANCH_PATIENT,
    ERROR_GET_MEDICAL_SCHEDULES_BY_BRANCH_PATIENT,
    INIT_CREATE_MEDICAL_SCHEDULE,
    CREATE_MEDICAL_SCHEDULE,
    ERROR_CREATE_MEDICAL_SCHEDULE,
    INIT_GET_CURRENT_MEDICAL_SCHEDULE,
    GET_CURRENT_MEDICAL_SCHEDULE,
    ERROR_GET_CURRENT_MEDICAL_SCHEDULE,
    INIT_ACTIVE_SCHEDULE_FORM,
    ERROR_ACTIVE_SCHEDULE_FORM,
    ACTIVE_SCHEDULE_FORM,
    INIT_UPDATE_MEDICAL_SCHEDULE,
    UPDATE_MEDICAL_SCHEDULE,
    ERROR_UPDATE_MEDICAL_SCHEDULE,
    INIT_DELETE_MEDICAL_SCHEDULE,
    DELETE_MEDICAL_SCHEDULE,
    ERROR_DELETE_MEDICAL_SCHEDULE
} from 'happ-redux/happ-types';
import { 
    getMedicalSchedule, 
    getMedicalSchedulesByBranchPatient, 
    createMedicalSchedule,
    getCurrentMedicalSchedulesByBranchPatient,
    updateMedicalSchedule,
    deleteMedicalSchedule 
} from 'happ-redux/happ-services/medical-schedule/medical-schedule';

export function getMedicalScheduleAction(navigate){
    return async (dispatch) => {
        dispatch(initMedicalSchedule());
        try {
            const token = localStorage.getItem('token');
            if (token === null){
                dispatch(logout());
                navigate('/login');
            }
            const response = await getMedicalSchedule(token);
            if (response.status === 401){
                dispatch(logout());
                navigate('/login');
            }
            if (response.status === 200) {
                dispatch(medicalSchedule(response.data));
            } else {
                dispatch(errorMedicalSchedule(response.data.msg));
            }
        } catch (error) {
            dispatch(errorMedicalSchedule(error.data.msg));
        }
    }
}

export function createMedicalScheduleAction(body) {
    return async dispatch => {
        dispatch(initNewSchedule());
        try {
            const response = await createMedicalSchedule(body);
            if (response.status === 201) {
                dispatch(newSchedule(response.data));
            } else {
                dispatch(errorNewSchedule());
            }
        } catch (error) {
            dispatch(errorNewSchedule());
        }
    }
}

export function updateMedicalScheduleAction(_id, newSchedule) {
    return async dispatch => {
        dispatch(initUpdateMedicalSchedule());
        try {
            const response = await updateMedicalSchedule(_id, newSchedule);
            if (response.status === 200) {
                dispatch(confirmUpdateMedicalSchedule(response.data));
            } else {
                dispatch(errorUpdateMedicalSchedule());
            }
        } catch (error) {
            dispatch(errorUpdateMedicalSchedule());
        }
    }
}

export function deleteMedicalScheduleAction(_id, navigate) {
    return async dispatch => {
        dispatch(initDeleteSchedule());
        try {
            const response = await deleteMedicalSchedule(_id);
            if (response.status === 200) {
                dispatch(deleteSchedulePatient(response.data));
                navigate('/');
            } else {
                dispatch(errordeleteSchedulePatient());
            }
        } catch (error) {
            dispatch(errordeleteSchedulePatient());
        }
    }
}

export function getMedicalSchedulesByBranchPatientAction(branch, medicalStage, holding, navigate){
    return async (dispatch) => {
        dispatch(initMedicalScheduleByBranchPatient());
        try {
            const token = localStorage.getItem('token');
            if (token === null){
                dispatch(logout());
                navigate('/login');
            }
            const response = await getMedicalSchedulesByBranchPatient(branch, medicalStage, holding, token);
            if (response.status === 401){
                dispatch(logout());
                navigate('/login');
            }
            if (response.status === 200) {
                dispatch(medicalScheduleByBranchPatient(response.data));
            } else {
                dispatch(errorMedicalScheduleByBranchPatient(response.data.msg));
            }
        } catch (error) {
            dispatch(errorMedicalScheduleByBranchPatient(error.data.msg));
        }
    }
}

export function getCurrentMedicalSchedulesByBranchPatientAction(patient, branch, medicalStage, holding, navigate){
    return async (dispatch) => {
        dispatch(initCurrentMedicalScheduleByBranchPatient());
        try {
            const token = localStorage.getItem('token');
            if (token === null){
                dispatch(logout());
                navigate('/login');
            }
            const response = await getCurrentMedicalSchedulesByBranchPatient(patient, branch, medicalStage, holding, token);
            if (response.status === 401){
                dispatch(logout());
                navigate('/login');
            }
            if (response.status === 200) {
                dispatch(currentmedicalScheduleByBranchPatient(response.data));
            } else {
                dispatch(errorCurrentMedicalScheduleByBranchPatient(response.data.msg));
            }
        } catch (error) {
            dispatch(errorCurrentMedicalScheduleByBranchPatient(error.data.msg));
        }
    }
}

export function activeScheduleFormAction(branch, medicalStage, holding, navigate) {
    return async (dispatch) => {
        dispatch(initActiveScheduleForm());
        try {
            const token = localStorage.getItem('token');
            if (token === null){
                dispatch(logout());
                navigate('/login');
            }
            const response = await getMedicalSchedulesByBranchPatient(branch, medicalStage, holding, token);
            if (response.status === 401){
                dispatch(logout());
                navigate('/login');
            }
            if (response.status === 200) {
                dispatch(activeScheduleForm(response.data));
            } else {
                dispatch(errorActiveScheduleForm(response.data.msg));
            }
        } catch (error) {
            dispatch(errorActiveScheduleForm(error.data.msg));
        }
    }
}

const initDeleteSchedule = () => ({
    type: INIT_DELETE_MEDICAL_SCHEDULE,
})
const deleteSchedulePatient = (response) => ({
    type: DELETE_MEDICAL_SCHEDULE,
    payload: response, 
})
const errordeleteSchedulePatient = () => ({
    type: ERROR_DELETE_MEDICAL_SCHEDULE,
})


const initUpdateMedicalSchedule = () => ({
    type: INIT_UPDATE_MEDICAL_SCHEDULE,
})
const confirmUpdateMedicalSchedule = (response) => ({
    type: UPDATE_MEDICAL_SCHEDULE,
    payload: response,
})
const errorUpdateMedicalSchedule = () => ({
    type: ERROR_UPDATE_MEDICAL_SCHEDULE,
})

const initActiveScheduleForm = () => ({
    type: INIT_ACTIVE_SCHEDULE_FORM,
});

const activeScheduleForm = (response) => ({
    type: ACTIVE_SCHEDULE_FORM,
    payload: response,
});

const errorActiveScheduleForm = (error) => ({
    type: ERROR_ACTIVE_SCHEDULE_FORM,
    payload: error,
}); 

const initCurrentMedicalScheduleByBranchPatient = () => ({
    type: INIT_GET_CURRENT_MEDICAL_SCHEDULE,
})
const currentmedicalScheduleByBranchPatient = (response) => ({
    type: GET_CURRENT_MEDICAL_SCHEDULE,
    payload: response,
})
const errorCurrentMedicalScheduleByBranchPatient = (response) => ({
    type: ERROR_GET_CURRENT_MEDICAL_SCHEDULE,
    payload: response,
})

const initNewSchedule = () => ({
    type: INIT_CREATE_MEDICAL_SCHEDULE,
});

const newSchedule = (response) => ({
    type: CREATE_MEDICAL_SCHEDULE,
    payload: response
});

const errorNewSchedule = () => ({
    type: ERROR_CREATE_MEDICAL_SCHEDULE,
});

const initMedicalScheduleByBranchPatient = () => ({
    type: INIT_GET_MEDICAL_SCHEDULES_BY_BRANCH_PATIENT,
});

const medicalScheduleByBranchPatient = (response) => ({
    type: GET_MEDICAL_SCHEDULES_BY_BRANCH_PATIENT,
    payload: response,
});

const errorMedicalScheduleByBranchPatient = (error) => ({
    type: ERROR_GET_MEDICAL_SCHEDULES_BY_BRANCH_PATIENT,
    payload: error,
});

const initMedicalSchedule = () => ({
    type: INIT_MEDICAL_SCHEDULE
});

const medicalSchedule = response => ({
    type: MEDICAL_SCHEDULE,
    payload: response
});

const errorMedicalSchedule = error => ({
    type: ERROR_MEDICAL_SCHEDULE,
    payload: error
});

const logout = () => ({
    type: LOGOUT
});