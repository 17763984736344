import axiosClient from 'happ-config/axios';

const sendPatientDataService = async (body, token) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization':`Bearer ${token}`
        };
        const response = await axiosClient.put('/consent-signatures/profile', body, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const getPreviewPdfService = async (token) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization':`Bearer ${token}`
        };
        const responseType = 'blob';
        const response = await axiosClient.get('/consent-signatures/profile/preview-pdf',{
            responseType,
            headers
        });

        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const confirmConsentService = async (token) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization':`Bearer ${token}`
        };
        const response = await axiosClient.post('/consent-signatures/profile/accept-terms',{}, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const validateCodeService = async (body, token) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization':`Bearer ${token}`
        };
        const response = await axiosClient.post('/consent-signatures/profile/confirm-terms',body, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}



const resetInformedConsentService = async (token) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization':`Bearer ${token}`
        };
        const response = await axiosClient.post('/consent-signatures/profile/reset-terms', {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}


export {
    sendPatientDataService,
    getPreviewPdfService,
    confirmConsentService,
    resetInformedConsentService,
    validateCodeService,
}