// User Login and Logout
export const INIT_SESSION = 'INIT_SESSION';
export const LOGIN = 'LOGIN';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const INIT_LOGOUT = 'INIT_LOGOUT';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';
export const INIT_UPDTAE_PROFILE = 'INIT_UPDTAE_PROFILE';
export const UPDTAE_PROFILE = 'UPDTAE_PROFILE';
export const UPDTAE_PROFILE_ERROR = 'UPDTAE_PROFILE_ERROR';
export const SET_UPDTAE_PROFILE_STATUS = 'SET_UPDTAE_PROFILE_STATUS';
export const VALIDATE_TERMS_AND_CONDITIONS = 'VALIDATE_TERMS_AND_CONDITIONS';

//Forget Password
export const INIT_FORGET_PASSWORD = 'INIT_FORGET_PASSWORD';
export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const FORGET_PASSWORD_ERROR = 'FORGET_PASSWORD_ERROR';

//Reset password
export const INIT_RESET_PASSWORD = 'INIT_RESET_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

// Session
export const INIT_VALIDATE_SESSION = 'INIT_VALIDATE_SESSION';
export const VALIDATE_SESSION = 'VALIDATE_SESSION';
export const VALIDATE_SESSION_ERROR = 'VALIDATE_SESSION_ERROR';

// User Profile
export const INIT_ME = 'INIT_ME';
export const ME = 'ME';
export const ME_ERROR = 'ME_ERROR';

//Health Score
export const INIT_HEALTH_SCORE = 'INIT_HEALTH_SCORE';
export const HEALTH_SCORE = 'HEALTH_SCORE';
export const ERROR_HEALTH_SCORE = 'ERROR_HEALTH_SCORE';

//Exam History
export const INIT_EXAM_HISTORY = 'INIT_EXAM_HISTORY';
export const EXAM_HISTORY = 'EXAM_HISTORY';
export const ERROR_EXAM_HISTORY = 'ERROR_EXAM_HISTORY';

//Medical Schedule
export const INIT_MEDICAL_SCHEDULE = 'INIT_MEDICAL_SCHEDULE';
export const MEDICAL_SCHEDULE = 'MEDICAL_SCHEDULE';
export const ERROR_MEDICAL_SCHEDULE = 'ERROR_MEDICAL_SCHEDULE';

//Medical Advice
export const INIT_MEDICAL_ADVICE = 'INIT_MEDICAL_ADVICE';
export const MEDICAL_ADVICE = 'MEDICAL_ADVICE';
export const ERROR_MEDICAL_ADVICE = 'ERROR_MEDICAL_ADVICE';

//Medical Goals
export const INIT_MEDICAL_GOALS = 'INIT_MEDICAL_GOALS';
export const MEDICAL_GOALS = 'MEDICAL_GOALS';
export const ERROR_MEDICAL_GOALS = 'ERROR_MEDICAL_GOALS';
export const INIT_ADD_MEDICAL_GOAL_RECORD = 'INIT_ADD_MEDICAL_GOAL_RECORD';
export const ADD_MEDICAL_GOAL_RECORD = 'ADD_MEDICAL_GOAL_RECORD';
export const ERROR_ADD_MEDICAL_GOAL_RECORD = 'ERROR_ADD_MEDICAL_GOAL_RECORD';

// schedule
export const INIT_SCHEDULE = 'INIT_SCHEDULE'; 
export const SCHEDULE_MEDICAL = 'SCHEDULE_MEDICAL';
export const SCHEDULE_MEDICAL_ERROR = 'SCHEDULE_MEDICAL_ERROR';

// get scheduling
export const INIT_GET_SCHEDULING = 'INIT_GET_SCHEDULING';
export const GET_SCHEDULING = 'GET_SCHEDULING';
export const GET_SCHEDULING404 = 'GET_SCHEDULING404';
export const ERROR_SCHEDULING = 'ERROR_SCHEDULING';



// post
export const INIT_ADD_SCHEDULE = 'INIT_ADD_SCHEDULE';
export const ADD_SCHEDULE = 'ADD_SCHEDULE';
export const ERROR_ADD_SCHEDULE = 'ERROR_ADD_SCHEDULE';

// delete
export const INIT_DELETE_SCHEDULE = 'INIT_DELETE_SCHEDULE';
export const DELETED_SCHEDULE = 'DELETED_SCHEDULE';
export const DELETE_ERROR = 'DELETE_ERROR';

// UPDATE
export const INIT_UPDATE = 'INIT_UPDATE';
export const INIT_SCHEDULE_FORM = 'INIT_SCHEDULE_FORM';
export const PROCCESS_UPDATE = 'PROCCESS_UPDATE';
export const ERROR_UPDATE = 'ERROR_UPDATE';


//MedicalStageDElivery
export const INIT_MEDICAL_STAGE_DELIVERY = 'INIT_MEDICAL_STAGE_DELIVERY';
export const MEDICAL_STAGE_DELIVERY = 'MEDICAL_STAGE_DELIVERY';
export const ERROR_MEDICAL_STAGE_DELIVERY = 'ERROR_MEDICAL_STAGE_DELIVERY';

//Medismart Scheduling
export const INIT_GET_SCHEDULE_AVAILABILITY = 'INIT_GET_SCHEDULE_AVAILABILITY';
export const GET_SCHEDULE_AVAILABILITY = 'GET_SCHEDULE_AVAILABILITY';
export const ERROR_GET_SCHEDULE_AVAILABILITY = 'ERROR_GET_SCHEDULE_AVAILABILITY';

export const INIT_GET_FILTER_SCHEDULES = 'INIT_GET_FILTER_SCHEDULES';
export const GET_FILTER_SCHEDULES = 'GET_FILTER_SCHEDULES';
export const ERROR_GET_FILTER_SCHEDULES = 'ERROR_GET_FILTER_SCHEDULES';

export const INIT_CONFIRM_SCHEDULE = 'INIT_CONFIRM_SCHEDULE';
export const CONFIRM_SCHEDULE = 'CONFIRM_SCHEDULE';
export const ERROR_CONFIRM_SCHEDULE = 'ERROR_CONFIRM_SCHEDULE';

export const INIT_CHECK_SCHEDULES = 'INIT_CHECK_SCHEDULES';
export const CHECK_SCHEDULES = 'CHECK_SCHEDULES';
export const ERROR_CHECK_SCHEDULES = 'ERROR_CHECK_SCHEDULES';

export const INIT_CURRENT_SCHEDULES = 'INIT_CURRENT_SCHEDULES';
export const CURRENT_SCHEDULES = 'CURRENT_SCHEDULES';
export const ERROR_CURRENT_SCHEDULES = 'ERROR_CURRENT_SCHEDULES';

export const INIT_DELETE_SCHEDULE_M = 'INIT_DELETE_SCHEDULE_M';
export const DELETE_SCHEDULE_M = 'DELETE_SCHEDULE_M';
export const ERROR_DELETE_SCHEDULE_M = 'ERROR_DELETE_SCHEDULE_M';

export const CLEAN_ALL_SCHEDULE = 'CLEAN_ALL_SCHEDULE';
export const CLEAN_ALL_SCHEDULE_MEDISMART = 'CLEAN_ALL_SCHEDULE_MEDISMART';


//OAuth
export const FETCH_OAUTH_CLIENT_DATA = 'FETCH_OAUTH_CLIENT_DATA';
export const SET_REDIRECT_URL = 'SET_REDIRECT_URL'
export const SET_OAUTH_LOADING = 'SET_OAUTH_LOADING'

//Mental Health
export const INIT_GET_PHQ9_CALCULATION = 'INIT_GET_PHQ9_CALCULATION';
export const GET_PHQ9_CALCULATION = 'GET_PHQ9_CALCULATION';

export const INIT_SEND_MENTAL_HEALTH_SURVEY = 'INIT_SEND_MENTAL_HEALTH_SURVEY';
export const SEND_MENTAL_HEALTH_SURVEY = 'SEND_MENTAL_HEALTH_SURVEY';
export const ERROR_SEND_MENTAL_HEALTH_SURVEY = 'ERROR_SEND_MENTAL_HEALTH_SURVEY';
export const CLEAN_SURVEY = 'CLEAN_SURVEY';


export const INIT_GET_LAST_PRESCRIPTION = 'INIT_GET_LAST_PRESCRIPTION';
export const GET_LAST_PRESCRIPTION = 'GET_LAST_PRESCRIPTION';

// cancer journey
export const INIT_SEND_CANCER_SURVEY = 'INIT_SEND_CANCER_SURVEY';
export const SEND_CANCER_SURVEY = 'SEND_CANCER_SURVEY';
export const ERROR_SEND_CANCER_SURVEY = 'ERROR_SEND_CANCER_SURVEY';
export const CLEAN_CANCER_SURVEY = 'CLEAN_CANCER_SURVEY';
export const INIT_GET_LAST_CANCER_PRESCRIPTION = 'INIT_GET_LAST_CANCER_PRESCRIPTION';
export const GET_LAST_CANCER_PRESCRIPTION = 'GET_LAST_CANCER_PRESCRIPTION';
export const ERROR_GET_LAST_CANCER_PRESCRIPTION = 'ERROR_GET_LAST_CANCER_PRESCRIPTION';
export const INIT_GET_CANCER_SURVEY_CALCULATION = 'INIT_GET_CANCER_SURVEY_CALCULATION';
export const GET_CANCER_SURVEY_CALCULATION = 'GET_CANCER_SURVEY_CALCULATION';


// New Medical Agenda
export const INIT_GET_MEDICAL_SCHEDULES_BY_BRANCH_PATIENT = 'INIT_GET_MEDICAL_SCHEDULES_BY_BRANCH_PATIENT';
export const GET_MEDICAL_SCHEDULES_BY_BRANCH_PATIENT = 'GET_MEDICAL_SCHEDULES_BY_BRANCH_PATIENT';
export const ERROR_GET_MEDICAL_SCHEDULES_BY_BRANCH_PATIENT = 'ERROR_GET_MEDICAL_SCHEDULES_BY_BRANCH_PATIENT'; 

export const INIT_CREATE_MEDICAL_SCHEDULE = 'INIT_CREATE_MEDICAL_SCHEDULE';
export const CREATE_MEDICAL_SCHEDULE = 'CREATE_MEDICAL_SCHEDULE';
export const ERROR_CREATE_MEDICAL_SCHEDULE = 'ERROR_CREATE_MEDICAL_SCHEDULE';

export const INIT_GET_CURRENT_MEDICAL_SCHEDULE = 'INIT_GET_CURRENT_MEDICAL_SCHEDULE';
export const GET_CURRENT_MEDICAL_SCHEDULE = 'GET_CURRENT_MEDICAL_SCHEDULE';
export const ERROR_GET_CURRENT_MEDICAL_SCHEDULE = 'ERROR_GET_CURRENT_MEDICAL_SCHEDULE';

export const INIT_ACTIVE_SCHEDULE_FORM = 'INIT_ACTIVE_SCHEDULE_FORM';
export const ACTIVE_SCHEDULE_FORM = 'ACTIVE_SCHEDULE_FORM';
export const ERROR_ACTIVE_SCHEDULE_FORM = 'ERROR_ACTIVE_SCHEDULE_FORM';

export const INIT_UPDATE_MEDICAL_SCHEDULE = 'INIT_UPDATE_MEDICAL_SCHEDULE';
export const UPDATE_MEDICAL_SCHEDULE = 'UPDATE_MEDICAL_SCHEDULE';
export const ERROR_UPDATE_MEDICAL_SCHEDULE = 'ERROR_UPDATE_MEDICAL_SCHEDULE';

export const INIT_DELETE_MEDICAL_SCHEDULE = 'INIT_DELETE_MEDICAL_SCHEDULE';
export const DELETE_MEDICAL_SCHEDULE = 'DELETE_MEDICAL_SCHEDULE';
export const ERROR_DELETE_MEDICAL_SCHEDULE = 'ERROR_DELETE_MEDICAL_SCHEDULE';


// Informed Consent
export const INIT_INFORMED_CONSENT = 'INIT_INFORMED_CONSENT';

export const INIT_SEND_PATIENT_DATA = 'INIT_SEND_PATIENT_DATA';
export const SEND_PATIENT_DATA = 'SEND_PATIENT_DATA';
export const ERROR_SEND_PATIENT_DATA = 'ERROR_SEND_PATIENT_DATA';

export const INIT_INFORMED_CONSENT_PREVIEW = 'INIT_INFORMED_CONSENT_PREVIEW';
export const INFORMED_CONSENT_PREVIEW = 'INFORMED_CONSENT_PREVIEW';
export const ERROR_INFORMED_CONSENT_PREVIEW = 'ERROR_GET_INFORMED_CONSENT';

export const INIT_CONFIRM_INFORMED_CONSENT = 'INIT_CONFIRM_INFORMED_CONSENT';
export const INFORMED_CONSENT_CONFIRMED = 'GET_INFORMED_CONSENT_CONFIRMED ';
export const ERROR_INFORMED_CONSENT_CONFIRM = 'ERROR_INFORMED_CONSENT_CONFIRM';

export const INIT_CONSENT_CODE_VALIDATION = 'INIT_CONSENT_CODE_VALIDATION';
export const CONSENT_CODE_VALIDATION = 'CONSENT_CODE_VALIDATION';
export const ERROR_CONSENT_CODE_VALIDATION = 'ERROR_CONSENT_CODE_VALIDATION';

export const INIT_CONSENT_VALIDATE_CODE = 'INIT_CONSENT_VALIDATE_CODE';
export const CONSENT_CODE_VALIDATED = 'CONSENT_CODE_VALIDATED';
export const ERROR_CONSENT_VALIDATE_CODE = 'ERROR_CONSENT_VALIDATE_CODE';

export const RESET_INFORMED_CONSENT = 'RESET_INFORMED_CONSENT';
export const ERROR_RESET_INFORMED_CONSENT = 'ERROR_RESET_INFORMED_CONSENT';



// Telehealth Provider
export const INIT_CHECK_TELEHEALTH_PROVIDER = 'INIT_CHECK_TELEHEALTH_PROVIDER'; 
export const CHECK_TELEHEALTH_PROVIDER = 'CHECK_TELEHEALTH_PROVIDER'; 
export const ERROR_CHECK_TELEHEALTH_PROVIDER = 'ERROR_CHECK_TELEHEALTH_PROVIDER'; 

export const INIT_GET_MEDICAL_PRESCRIPTIONS = 'INIT_GET_MEDICAL_PRESCRIPTIONS';
export const GET_MEDICAL_PRESCRIPTIONS = 'GET_MEDICAL_PRESCRIPTIONS';
export const ERROR_GET_MEDICAL_PRESCRIPTIONS = 'ERROR_GET_MEDICAL_PRESCRIPTIONS';